import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { HttpErrorInterceptor } from 'app/main/general/http-error.interceptor';

import { SharedModule } from './main/shared/shared.module';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { UsuariosModule } from './main/usuarios/usuarios.module';
import { EmpleadosModule } from './main/empleados/empleados.module';
import { InventarioModule } from './main/inventario/inventario.module';
import { TiposServicioModule } from './main/tiposservicio/tiposservicio.module';
import { PreguntasModule } from './main/preguntas/preguntas.module';
import { CuadrillasModule } from './main/cuadrillas/cuadrillas.module';
import { GasolinerasModule } from './main/gasolineras/gasolineras.module';
import { GruasModule } from './main/gruas/gruas.module';
import { ChecklistModule } from './main/checklist/checklist.module';
import { OrdenesModule } from './main/ordenes/ordenes.module';
import { LoginModule } from './main/login/login.module';

import { FakeDbService } from 'app/fake-db/fake-db.service';




const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: '/sample'
    }
    /*,
    {
        path            : 'apps',
        loadChildren    : () => import('./main/usuarios/usuarios.module').then(m => m.UsuariosModule)
    }*/
];

@NgModule({
    declarations: [
        AppComponent

    ],
    imports     : [

        SharedModule,

        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        SharedModule,
        LayoutModule,
        SampleModule,
        UsuariosModule,
        EmpleadosModule,
        InventarioModule,
        TiposServicioModule,
        PreguntasModule,
        CuadrillasModule,
        GasolinerasModule,
        GruasModule,
        ChecklistModule,
        OrdenesModule,
        LoginModule

    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ],
    bootstrap   : [
        AppComponent
    ],
    exports: [
        SharedModule,
    ]
})
export class AppModule
{
}
