import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { User } from '../../../models/users.model';
import {OrdenesService} from '../ordenes.service';
import {Point} from '../../../models/point.model';
import {Checklist} from '../../../models/checklist.model';

import * as moment from 'moment';

@Component({
  selector: 'app-ordenes-sidebars',
  templateUrl: './ordenes-sidebars.component.html',
  styleUrls: ['./ordenes-sidebars.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class OrdenesSidebarsComponent implements OnInit, OnDestroy {

    tags: any[];

    // Para filtro de operadores
    sinOperador: User;
    selectedOperador: User;
    dataOperadores: User[];

    // Para filtro de Sitios
    sinSitio: Point;
    selectedSitio: Point;
    dataSitio: Point[];

    // Para filtro de Sitios
    sinChecklist: Checklist;
    selectedChecklist: Checklist;
    dataChecklist: Checklist[];

    // Para fechas
    minDateDesde: Date;
    minDateHasta: Date;
    maxDateHasta: Date;
    startDate: Date;
    dateIni: FormControl;
    dateFin: FormControl;

    diasSeleccionFechas: number;

    // Sobre formulario
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    selectedStatus: number;

    @ViewChild('picker2') public pickerEnd: MatDatepicker<any>;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _ordenesService
     * @param _router
     */
    constructor(
        private _ordenesService: OrdenesService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.selectedStatus = 0;
        this.diasSeleccionFechas = 14;
        this.sinOperador = new User().deserialize({id: -1, name: 'Todos'});
        this.sinSitio = new Point().deserialize({id: -1, name: 'Todos'});
        this.sinChecklist = new Checklist().deserialize({id: -1, name: 'Todos'});
        this.startDate = new Date();
        this.minDateDesde = moment(this.startDate).add((this.diasSeleccionFechas * -1), 'days').toDate();;
        this.minDateHasta = this.minDateDesde;
        this.maxDateHasta = moment(this.minDateHasta).add(this.diasSeleccionFechas, 'days').toDate();

        this.dateIni = new FormControl(this.minDateDesde, [Validators.required]);
        this.dateFin = new FormControl(this.maxDateHasta, [Validators.required]);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        // Carga información de los operadores
        this._ordenesService.onOperadoresChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.dataOperadores = data.filter(dataOper => ( dataOper.role & 2 ) === 2);
                this.selectedOperador = new User();
            });

        // Carga información del los inventarios
        this._ordenesService.onSitiosChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.dataSitio = data;
                this.selectedSitio = new Point();
            });

        // Carga información de los checklist
        this._ordenesService.onChecklistsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.dataChecklist = data;
                this.selectedChecklist = new Checklist();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Call service for get all orders by flters
     */
    buscarOrdenes(): void {
        const dateSelectedIni: Date = new Date(this.dateIni.value);
        const dateSelectedFin: Date = new Date(this.dateFin.value);

        this._ordenesService.setSeccion('listado');
        this._ordenesService.getOrdenesByFilter(this.selectedOperador, this.selectedSitio, this.selectedChecklist, this.dateIni.value, this.dateFin.value, this.selectedStatus);
    }

    addEventDatePicker(control: string, type: string, event: MatDatepickerInputEvent<Date>): void {
        const dateSelected: Date = new Date(event.value);

        if (control == 'from') {
            switch (type) {
                case 'change':
                    this.minDateHasta = dateSelected;
                    this.maxDateHasta = moment(this.minDateHasta).add(this.diasSeleccionFechas, 'days').toDate();
                    this.dateFin = new FormControl(this.maxDateHasta, [Validators.required]);
                    break;

            }
        } else if (control == 'to') {

        }
    }

}
