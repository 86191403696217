<div id="page-ordenes" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar sidebar_ordenes" name="ordenes-main-sidebar" position="left" lockedOpen="gt-md">
        <app-ordenes-sidebars></app-ordenes-sidebars>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header accent p-24 pb-4" fxLayout="row" fxLayoutAlign="center center">

            <div class="" fxFlex fxLayout="row" fxLayoutAlign="space-between center">

                <button mat-icon-button class="sidebar-toggle" fxHide.gt-md
                        (click)="toggleSidebar('ordenes-main-sidebar')">
                    <mat-icon>menu</mat-icon>
                </button>

                <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon s-32" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">format_list_bulleted</mat-icon>
                    <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">ORDENES  </span>
                </div>

                <!-- ACTIONS -->
                <div class="toolbar-action-button-row" fxLayout="row" fxLayoutAlign="end center" *ngIf="currentSeccion=='' || currentSeccion=='listado'" >
                    <button mat-raised-button color="primary" aria-label="Crear Ordenes" title="Crear Ordenes" (click)="crearOrdenes()">Crear Ordenes</button>
                </div>
                <div class="toolbar-action-button-row" fxLayout="row" fxLayoutAlign="end center" *ngIf="currentSeccion=='checklist'" >
                    <button mat-raised-button color="primary" aria-label="Crear Ordenes" title="Crear Ordenes" (click)="regresarListado()">Regresar</button>
                    <button mat-raised-button color="primary" aria-label="Crear Ordenes" title="Crear Ordenes" (click)="aceptarValores()">Aceptar Información</button>
                </div>
                <!-- / ACTIONS -->

                <!--
                <div class="search" fxLayout="row" fxLayoutAlign="start center" *ngIf="!currentTeamEmpleados">
                    <mat-icon class="secondary-text">search</mat-icon>
                    <input [formControl]="searchInput" placeholder="Busca una cuadrilla" fxFlex>
                </div>
                -->

            </div>

        </div>

        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->


        <div class="content-card" >

            <!--
            <app-cuadrillas-lista *ngIf="!currentTeamEmpleados"></app-cuadrillas-lista>
            -->

            <!--
            <app-empleados-lista *ngIf="currentTeamEmpleados"></app-empleados-lista>
            -->


            <!-- CONTENT -->


            <div class="content" >
                <app-ordenes-listado *ngIf="currentSeccion=='' || currentSeccion=='listado'"></app-ordenes-listado>
                <app-ordenes-checklist-listado *ngIf="currentSeccion=='checklist'"></app-ordenes-checklist-listado>
                <!--
                <preguntas-list fusePerfectScrollbar fxFlex></preguntas-list>
                <preguntas-details fusePerfectScrollbar fxFlex></preguntas-details>
                -->

            </div>


            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
