<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" >
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-selection-list #questions>
                    <mat-list-option *ngFor="let question of questionsList" [value]="question">
                        <mat-icon matListIcon>folder</mat-icon>
                        {{question.description}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <p>
                    Options selected: {{questions.selectedOptions.selected.length}}
                </p>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="action === 'new' && questions.selectedOptions.selected.length > 0"
                mat-raised-button
                class="mat-accent"
                (click)="guardarDatos(questions.selectedOptions.selected)"
                aria-label="Guardar">
            Guardar
        </button>
    </div>
</div>
