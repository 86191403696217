import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Truck } from 'app/models/truck.model';
import { TruckLocation } from 'app/models/truck-location.model';
import { GruasService } from 'app/main/gruas/gruas.service';

@Component({
    selector     : 'gruas-mapa-dialog',
    templateUrl  : './gruas-mapa.component.html',
    styleUrls    : ['./gruas-mapa.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GruasMapaDialogComponent implements OnInit
{
    gruaId: number;
    locationId: number;
    action: string;
    dataGrua: Truck;
    dataLocation: TruckLocation;
    dataLocationForm: FormGroup;
    dialogTitle: string;
    lat: number;
    lng: number;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<GruasMapaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _gruasService: GruasService,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the defaults
        this.dialogTitle = 'Ubicación de Grúa';
        this.action = _data.action;
        if (this.action === 'showLast'){
            this.gruaId = _data.gruaId;
            this.dataGrua = _data.dataCheck;
            this.dataLocation = new TruckLocation({
                truck: new Truck()
            });
            this.dataLocationForm = this.createGroupForm();
        } else if (this.action === 'show'){
            this.gruaId = _data.gruaId;
            this.locationId = _data.locationId;
            this.dataGrua = _data.dataCheck;
            this.dataLocation = _data.dataLocation;
        }

        this.dataLocationForm = this.createGroupForm();

    }

    /**
     * On init
     */
    ngOnInit(): void {

        if (this.action == 'showLast') {

            // Obtiene el usuario
            this._gruasService.locationsGetLast(this.gruaId)
                .then((response: any) => {
                    if (response.data.trucklocations.length === 0){
                        this._matSnackBar.open('No se encontró ubicación', 'Error', {
                            verticalPosition: 'top',
                            duration: 3000
                        });
                        this.matDialogRef.close();
                    } else {
                        this.dataLocation = new TruckLocation(response.data.trucklocations[0]);
                        this.locationId = this.dataLocation.id;
                    }
                    this.dataLocationForm = this.createGroupForm();
                })
                .catch((error: any) => {
                    this._matSnackBar.open('Problema con la información', 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });

                    this.matDialogRef.close();
                });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataLocation.id],
            name: [{
                value: this.dataLocation.truck.name,
                disabled: true
            }],
            geolocation: [this.dataLocation.geolocation],
            geolocation_lat: [{
                value: this.dataLocation.geolocation.coordinates[0],
                disabled: true
            }],
            geolocation_long: [{
                value: this.dataLocation.geolocation.coordinates[0],
                disabled: true
            }],
            dt_request: [{
                value: this.dataLocation.dt_request,
                disabled: true
            }],
            dt_response: [{
                value: this.dataLocation.dt_response,
                disabled: true
            }]
        });
    }

}
