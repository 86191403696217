import {Deserializable} from './deserializable.model';

export class Staff{

    id: number;
    name: string;
    job: string;
    active: number;
    createdAt: any;
    updatedAt: any;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.name = data.name || '';
        this.job = data.job|| '';
        this.active = data.active || 0;
        this.createdAt = data.createdAt || null;
        this.updatedAt = data.updatedAt || null;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}