export const locale = {
    lang: 'es',
    data: {
        NAV: {
            APPLICATIONS: 'Módulos',
            SAMPLE: {
                TITLE: 'Inicio',
                BADGE: '25'
            },
            USUARIOS: {
                LIST: {
                    TITLE: 'Usuarios'
                }
            },
            EMPLEADOS: {
                LIST: {
                    TITLE: 'Empleados'
                }
            },
            INVENTARIO: {
                LIST: {
                    TITLE: 'Inventario'
                }
            },
            TIPOSSERVICIO: {
                LIST: {
                    TITLE: 'Tipos de servicio'
                }
            },
            PREGUNTAS: {
                LIST: {
                    TITLE: 'Banco de preguntas'
                }
            },
            CUADRILLAS: {
                LIST: {
                    TITLE: 'Cuadrillas'
                }
            },
            GASOLINERAS: {
                LIST: {
                    TITLE: 'Gasolineras'
                }
            },
            GRUAS: {
                LIST: {
                    TITLE: 'Grúas'
                }
            },
            CHECKLIST: {
                LIST: {
                    TITLE: 'Checklist'
                }
            },
            ORDENES: {
                LIST: {
                    TITLE: 'Ordenes'
                }
            },
            LOGIN: {
                LIST: {
                    TITLE: 'Login'
                }
            },
            GROUPS: {
                CATALOGOS: {
                    TITLE: 'Catálogos'
                }
            }
        }
    }
};
