export const locale = {
    lang: 'es',
    data: {
        GASOLINERAS: {
            ADD: {
                TITLE: 'NUEVO INVENTARIO'
            }
        }
    }
};
