// Angular y tema
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

// Módulo
import { OrdenesService } from 'app/main/ordenes/ordenes.service';
import { CuadrillasDetalleDialogComponent } from 'app/main/cuadrillas/detalle/cuadrillas-detalle.component'
import { Orders } from '../../../models/orders.model';

@Component({
  selector: 'app-ordenes-listado',
  templateUrl: './ordenes-listado.component.html',
  styleUrls: ['./ordenes-listado.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class OrdenesListadoComponent implements OnInit, OnDestroy {

    dialogRef: any;

    dataSource: FilesDataSource | null;
    displayedColumns = ['id', 'user_operator.name', 'site_points.data_code', 'dt_assignment', 'status', 'data_start_date', 'data_start_time', 'dt_start', 'acciones'];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    private titleModule: string;

    /**
     * Constructor
     *
     * @param _ordenesService
     * @param _fuseTranslationLoaderService
     * @param _matSnackBar
     * @param _router
     * @param _matDialog
     */
    constructor(
        private _ordenesService: OrdenesService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    )
    {
        // Set the private defaults
        this.titleModule = 'Órden';
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._ordenesService, this.paginator, this.sort);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    verChecklist(dataOrden: Orders): void {
        this._ordenesService.getOrdersChecklistByOrder(dataOrden);
        this._ordenesService.setSeccion('checklist');
    }

    ordenCambioEstatus(orderChecklistId: number, statusActual: number, statusNuevo: number): void {
        this._ordenesService.orderChangeStatus(orderChecklistId, statusActual, statusNuevo)
            .then((response: any) => {
                if (response.code === 200) {
                    this._matSnackBar.open('Orden actualizada', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._ordenesService.getOrdenes();
                } else {
                    this._matSnackBar.open(response.message, 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });
                }
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización de Orden', 'Error', {
                    verticalPosition: 'top',
                    duration: 3000
                });
            });
    }

    /**
     * Obtiene el protocolo de la orden seleccionada
     *
     * @param dataOrden
     */
    descargarProtocolo(dataOrden: Orders): void {
        this._ordenesService.getProtocol(dataOrden.id)
            .then((data) => {

                const newBlob = new Blob([( data )], { type: 'application/pdf' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const downloadURL = URL.createObjectURL(newBlob);
                window.open(downloadURL, '_blank');

            })
            .catch((error: any) => {
                this._matSnackBar.open('No se puede generar el protocolo', 'Error', {
                    verticalPosition: 'top',
                    duration: 3000
                });
            });

    }

}

export class FilesDataSource extends DataSource<any>
{
    // Private
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param _ordenesService
     * @param _matPaginator
     * @param _matSort
     */
    constructor(
        private _ordenesService: OrdenesService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    )
    {
        super();

        this.filteredData = [];
        // this.filteredData = this._cuadrillasService.dataCuadrillasAll;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this._ordenesService.onOrdenesChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {
                if (this._ordenesService.dataOrdenesAll) {
                    let data = this._ordenesService.dataOrdenesAll.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
            })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'user_operator.name':
                    [propertyA, propertyB] = [a.user_operator.name, b.user_operator.name];
                    break;
                case 'site_points.data_code':
                    [propertyA, propertyB] = [a.site_points.data_code, b.site_points.data_code];
                    break;
                case 'dt_assignment':
                    [propertyA, propertyB] = [a.dt_assignment, b.dt_assignment];
                    break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
                case 'data_start_date':
                    [propertyA, propertyB] = [a.data_start_date, b.data_start_date];
                    break;
                case 'data_start_time':
                    [propertyA, propertyB] = [a.data_start_time, b.data_start_time];
                    break;
                case 'dt_start':
                    [propertyA, propertyB] = [a.dt_start, b.dt_start];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}