<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="dataTeamStaffForm">

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Empleado</mat-label>
                    <mat-select class="account-selection" placeholder="Seleccione un empleado"
                                name="staff_id" formControlName="staff_id">
                        <mat-option *ngFor="let _dataEmpleado of dataEmpleados" [value]="_dataEmpleado.id">
                            {{_dataEmpleado.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Comentarios</mat-label>
                    <textarea name="comments" formControlName="comments" matInput type="text" max-rows="4" maxlength="255"></textarea>
                </mat-form-field>
            </div>


        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action !== 'edit'"
                mat-raised-button
                class="mat-accent"
                (click)="matDialogRef.close(dataTeamStaffForm)"
                [disabled]="dataTeamStaffForm.invalid"
                aria-label="Guardar">
            Guardar
        </button>

        <button *ngIf="action === 'edit'"
                mat-raised-button
                class="mat-accent"
                (click)="matDialogRef.close(['save',dataTeamStaffForm])"
                [disabled]="dataTeamStaffForm.invalid"
                aria-label="Guardar">
            Guardar
        </button>

    </div>

</div>
