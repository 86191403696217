<!-- SIDEBAR HEADER -->
<div fxLayout="column" fxLayoutAlign="flex-end start"
     class="header accent p-24 pb-4">

    <div fxLayout=" column" [@animate]="{value:'*',params:{delay:'300ms'}}">
        <div class="title mat-subheading-2 mb-0">Operadores </div>
        <mat-form-field floatLabel="never" class="cssSelectFilter" style="width: 100%;">
            <mat-select class="account-selection" placeholder="Seleccione un operador"
                        [(ngModel)]="selectedOperador"  >
                <mat-option [value]="sinOperador">Todos</mat-option>
                <mat-option *ngFor="let _dataOperador of dataOperadores" [value]="_dataOperador">
                    {{_dataOperador.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<!-- / SIDEBAR HEADER -->

<!-- SIDEBAR CONTENT -->
<div class="content" fusePerfectScrollbar>

    <div class="nav material2 px-24 pt-24">

        <div class="mat-subheading-3" >Filtro por fechas</div>

        <mat-form-field class="cssSelectFilter" style="width: 100%;">
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="picker"
                   [formControl]="dateIni"
                   (dateChange)="addEventDatePicker('from', 'change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="month" ></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="cssSelectFilter" style="width: 100%;">
            <mat-label>Fecha de fin</mat-label>
            <input matInput [min]="minDateHasta" [max]="maxDateHasta" [matDatepicker]="picker2"
                   [formControl]="dateFin"
                    >
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 startView="month" ></mat-datepicker>
        </mat-form-field>

    </div>

    <div class=" px-24 " fxLayout="column" >
        <div class="title mat-subheading-3 mb-0">Estatus </div>
        <mat-form-field floatLabel="never" class="cssSelectFilter">
            <mat-select class="account-selection" placeholder="Seleccione un Estatus"
                        [(ngModel)]="selectedStatus"  >
                <mat-option [value]="0">Todos</mat-option>
                <mat-option [value]="10">Nueva</mat-option>
                <mat-option [value]="20">En proceso</mat-option>
                <mat-option [value]="30">Concluida</mat-option>
                <mat-option [value]="40">Cerrada (Por admin)</mat-option>
                <mat-option [value]="50">Pendiente</mat-option>
                <mat-option [value]="90">Cancelada</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class=" px-24 " fxLayout="column" >
        <div class="title mat-subheading-3 mb-0">Sitios </div>
        <mat-form-field floatLabel="never" class="cssSelectFilter">
            <mat-select class="account-selection" placeholder="Seleccione un sitio"
                        [(ngModel)]="selectedSitio"  >
                <mat-option [value]="sinSitio">Todos</mat-option>
                <mat-option *ngFor="let _dataSitio of dataSitio" [value]="_dataSitio">
                    {{_dataSitio.data_code}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class=" px-24 " fxLayout="column" >
        <div class="title mat-subheading-3 mb-0">Checklist</div>
        <mat-form-field floatLabel="never" class="cssSelectFilter">
            <mat-select class="account-selection" placeholder="Seleccione un checklist"
                        [(ngModel)]="selectedChecklist"  >
                <mat-option [value]="sinChecklist">Todos</mat-option>
                <mat-option *ngFor="let _dataChecklist of dataChecklist" [value]="_dataChecklist">
                    {{_dataChecklist.service_type.name}} + ({{_dataChecklist.version}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="p-24">
        <button mat-raised-button
                class="mat-accent add-todo-button w-100-p"
                *ngIf="dateFin.valid"
                (click)="buscarOrdenes()"
                aria-label="Buscar">
            Buscar
        </button>
    </div>

</div>
<!-- / SIDEBAR CONTENT -->
