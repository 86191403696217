import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { Checklist } from '../../../models/checklist.model';
import { ServiceType } from '../../../models/service-type.model';
import { ChecklistService } from 'app/main/checklist/checklist.service';
import { GLOBAL } from '../../../global/global';

@Component({
    selector: 'app-checklist-edit',
    templateUrl: './checklist-edit.component.html',
    styleUrls: ['./checklist-edit.component.scss'],
    providers: [ChecklistService]
})
export class ChecklistEditComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public dataCheck: Checklist;
    private paramId: number;
    private titleModule: string;
    dataServiceType: ServiceType[];
    startDate: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _checklistService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _checklistService: ChecklistService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();
        this.titleModule = 'Checklist';

        // Parametros
        this._route.params.subscribe((params: Params) => {
            this.paramId = params.id;
        });

        let dateHoy = new Date();
        this.startDate = dateHoy;

        this._checklistService.serviceTypeGetAll()
            .then((response) => {
                this.dataServiceType = response.data.tiposservicio;
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en obtención de información', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {

        this.dataCheck = new Checklist();
        this.form = this.createDataForm();

        //Obtiene el usuario
        this._checklistService.getOne(this.paramId)
            .then((response: any) => {
                this.dataCheck = new Checklist(response.data.checklist);
                this.form = this.createDataForm();
            })
            .catch((error: any) => {
                this._matSnackBar.open('Problema con ' + this.titleModule, 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                this._router.navigate(['/checklist']);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataCheck.id],
            service_type_id: [this.dataCheck.service_type_id, [Validators.required, Validators.min(1)]],
            version: [this.dataCheck.version, [Validators.required]],
            dt_start: [this.dataCheck.dt_start, [Validators.required]],
        });
    }

    saveCheck(): void {
        this._checklistService.update(this.form.value)
            .then(() => {
                    this._matSnackBar.open(this.titleModule + ' modificada', '', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/checklist']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
