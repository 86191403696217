import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { User } from '../../../models/users.model';
import { UsuariosService } from 'app/main/usuarios/usuarios.service';

@Component({
    selector: 'app-usuarios-edit',
    templateUrl: './usuarios-edit.component.html',
    styleUrls: ['./usuarios-edit.component.scss'],
    providers: [UsuariosService]
})
export class UsuariosEditComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public user: User;
    private idData: number;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _usuariosService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _usuariosService: UsuariosService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();

        //Parametros
        this._route.params.subscribe((params: Params) => {
            this.idData = params.id;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {

        this.user = new User();
        this.form = this.createProductForm();

        //Obtiene el usuario
        this._usuariosService.getOne(this.idData)
            .then((response: any) => {
                this.user = new User(response.data.user);
                this.form = this.createProductForm();
            })
            .catch((error: any) => {
                this._matSnackBar.open('Problema con usuario', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                this._router.navigate(['/usuarios']);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createProductForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.user.id],
            name            : [this.user.name, [Validators.required]],
            role            : [this.user.role, [Validators.required]],
            email           : [this.user.email, [Validators.required, Validators.email]]
        });

    }

    saveUser(): void {
        this._usuariosService.update(this.form.value)
            .then(() => {
                    this._matSnackBar.open('Usuario modificado', '', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/usuarios']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
