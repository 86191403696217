import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';
import { User } from '../../../models/users.model';


@Component({
    selector     : 'cuadrillas-sidebar',
    templateUrl  : './cuadrillas-sidebar.component.html',
    styleUrls    : ['./cuadrillas-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CuadrillasSidebarComponent implements OnInit, OnDestroy
{
    tags: any[];
    sinOperador: User;
    selectedOperador: User;
    dataOperadores: User[];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    startDate: Date;
    dateIni: FormControl;
    dateFin: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _cuadrillasService
     * @param _router
     */
    constructor(
        private _cuadrillasService: CuadrillasService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.sinOperador = new User().deserialize({id: -1, name: 'Todos'});
        this.startDate = new Date();
        this.dateIni = new FormControl(new Date(), [Validators.required]);
        this.dateFin = new FormControl(new Date(), [Validators.required]);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        // Carga información del tipo del primer tipo de servicio y los servicios seleccionados
        this._cuadrillasService.onOperadoresChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {

                this.dataOperadores = data.filter(dataOper => ( dataOper.role & 2 ) === 2);

                // Selecciona el Tipo de servicio elegido
                if (this._cuadrillasService.currentIdOperador > 0) {
                    this.selectedOperador = this.dataOperadores
                        .find(userOperador => {
                            return Number(userOperador.id) === Number(this._cuadrillasService.currentIdOperador);
                        });
                } else {
                    this.selectedOperador = new User();
                }

            });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    selectTipo(): void{
        this._cuadrillasService.setCurrentIdOperador(this.selectedOperador.id);

        // this.getGruposPreguntas();
    }

    buscarCuadrillas(): void {
        const dateIni = new DatePipe('en').transform(this.dateIni.value, 'yyyy-MM-dd');
        const dateFin = new DatePipe('en').transform(this.dateFin.value, 'yyyy-MM-dd');

        this._cuadrillasService.setFilterData(this.selectedOperador.id, dateIni, dateFin);
        this._cuadrillasService.getCuadrillas();
    }


}
