// Angular y tema
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { takeUntil } from 'rxjs/internal/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Módulo
import { GasStation } from '../../../models/gas-station.model';
import { GasolinerasService } from '../gasolineras.service';
import {GasolinerasMapaDialogComponent} from '../gasolineras-mapa/gasolineras-mapa.component';

@Component({
    selector: 'app-gasolineras-lista',
    templateUrl: './gasolineras-lista.component.html',
    styleUrls: ['./gasolineras-lista.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],

})
export class GasolinerasListaComponent implements OnInit, OnDestroy {

    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    dataSource: FilesDataSource | null;
    displayedColumns = ['id', 'name', 'code', 'schedule', 'geolocation', 'acciones'];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    expandedElement: GasStation;

    // Private
    private _unsubscribeAll: Subject<any>;

    private titleModule: string;

    /**
     * Constructor
     *
     * @param _gasolinerasService
     * @param _fuseTranslationLoaderService
     * @param _matSnackBar
     * @param _router
     * @param _matDialog
     */
    constructor(
        private _gasolinerasService: GasolinerasService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this.titleModule = 'Gasolinera';
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._gasolinerasService, this.paginator, this.sort);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     *
     * @param actType
     * @param regId
     */
    deleteItem(regId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea eliminar la ' + this.titleModule + '?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._gasolinerasService.delete(regId)
                        .then((response: any) => {
                            if (response.code == 200)
                            {
                                this._matSnackBar.open(this.titleModule + ' eliminada', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(response.message, 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }
                            this._router.navigate(['/gasolineras']);
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }

    verMasInfo(dataGasStation): void {
        this.dialogRef = this._matDialog.open(GasolinerasMapaDialogComponent, {
            panelClass: 'gasolineras-mapa-dialog',
            data      : {
                gasId: dataGasStation.id,
                dataGasStation: dataGasStation,
                action : 'show'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {});
    }


}

export class FilesDataSource extends DataSource<any>
{
    // Private
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param _gasolinerasService
     * @param _matPaginator
     * @param _matSort
     */
    constructor(
        private _gasolinerasService: GasolinerasService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    )
    {
        super();

        this.filteredData = this._gasolinerasService.dataAll;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this._gasolinerasService.onOrdersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

                let data = this._gasolinerasService.dataAll.slice();

                data = this.filterData(data);

                this.filteredData = [...data];

                data = this.sortData(data);

                // Grab the page's slice of data.
                const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                return data.splice(startIndex, this._matPaginator.pageSize);
            })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
                case 'email':
                    [propertyA, propertyB] = [a.email, b.email];
                    break;
                case 'role':
                    [propertyA, propertyB] = [a.role, b.role];
                    break;
                case 'active':
                    [propertyA, propertyB] = [a.active, b.active];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
