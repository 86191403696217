import {Deserializable} from './deserializable.model';
import {Staff} from './staff.model';

export class TeamStaff{

    id: number;
    teams_id: number;
    staff_id: number;
    staff: Staff;
    comments: string;
    created_at: any;
    updated_at: any;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.staff = new Staff().deserialize(input.staff);

        return this;
    }
}