import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { GLOBAL } from '../../global/global';
import { LoginService } from 'app/main/login/login.service';
import { TruckLocation } from '../../models/truck-location.model';
import { Truck } from "../../models/truck.model";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ',
    })
};


@Injectable({
    providedIn: 'root'
})
export class GruasService implements Resolve<any>
{
    public urlAPI: string;
    dataAll: any[];
    dataAllLocation: TruckLocation[];
    onTrucksChanged: BehaviorSubject<any>;
    onLocationsChanged: BehaviorSubject<any>;
    currentTruck: Truck;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService
    )
    {
        // Set the defaults
        this.onTrucksChanged = new BehaviorSubject({});
        this.onLocationsChanged = new BehaviorSubject({});
        this.urlAPI = GLOBAL.url;
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            this.dataAllLocation = [];
            Promise.all([
                this.getData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get dataAll
     *
     * @returns {Promise<any>}
     */
    getData(): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'trucks', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.dataAll = response.data.trucks;
                        this.onTrucksChanged.next(this.dataAll);
                        resolve(response);
                 }, reject);
        });
    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    create(data): Promise<any>{
        let dataJson = data;
        dataJson.geolocation = {
            coordinates: [
                data.geolocation_lat,
                data.geolocation_long
            ]};

        const myData = {
            data: {
                truck: dataJson
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'trucks', myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get data of item
     * @param token
     * @param car
     */
    getOne(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'trucks/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Update data
     * @param token
     * @param car
     */
    update(data): Promise<any>{
        let dataJson = data;
        dataJson.geolocation = {
            coordinates: [
                data.geolocation_lat,
                data.geolocation_long
            ]};

        const myData = {
            data: {
                truck: dataJson
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'trucks/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    delete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'trucks/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    activate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        let httpOptions2 = httpOptions;
        httpOptions2.headers = httpOptions2.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'trucks/activate/' + id + '/' + type, myParam, httpOptions2).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get data of item
     * @param token
     * @param car
     */
    locationsGetLast(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'trucksloc/bytruck/' + id + '/last', httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get dataAll
     *
     * @returns {Promise<any>}
     */
    locationGetData(truckId: number, dateIni: string, dateFin: string): Promise<any>
    {
        let myData = {
            data: {
                filter: {
                    trucks_id: truckId,
                    date_ini: dateIni + ' 00:00:00',
                    date_end: dateFin + ' 23:59:59'
                }
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'trucksloc/filter', myParam, httpOptions)
                .subscribe(
                    (response: any) => {
                        this.dataAllLocation = response.data.trucklocations;
                        this.onLocationsChanged.next(this.dataAllLocation);
                        resolve(response);
                    }, reject);
        });
    }

    setCurrentGrua(dataGrua: Truck): void{
        this.currentTruck = dataGrua;
    }

}
