import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { Point } from '../../../models/point.model';
import { InventarioService } from 'app/main/inventario/inventario.service';
import { GLOBAL } from '../../../global/global';

@Component({
    selector: 'app-inventario-add',
    templateUrl: './inventario-add.component.html',
    styleUrls: ['./inventario-add.component.scss'],
    providers: [InventarioService]
})
export class InventarioAddComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public dataPoint: Point;
    private titleModule: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _inventarioService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _inventarioService: InventarioService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();
        this.titleModule = 'Inventario';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {
        this.dataPoint = new Point();
        // Reactive Form
        this.form = this.createDataForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.dataPoint.id],
            data_code       : [this.dataPoint.data_code, [Validators.required]],
            data_type       : [this.dataPoint.data_type, [Validators.required]],
            data_ip         : [this.dataPoint.data_ip, [Validators.required]],
            data_sector     : [this.dataPoint.data_sector, [Validators.required]],
            data_c2         : [this.dataPoint.data_c2, [Validators.required]],
            data_direction  : [this.dataPoint.data_direction, [Validators.required, Validators.maxLength(255)]],
            geolocation_lat     : [0, [Validators.required, Validators.pattern(GLOBAL.validators.coordinates)]],
            geolocation_long    : [0, [Validators.required, Validators.pattern(GLOBAL.validators.coordinates)]],
            active          : [this.dataPoint.active]
        });

    }

    saveInventario(): void {
        this._inventarioService.create(this.form.value)
            .then(() => {
                    this._matSnackBar.open(this.titleModule + ' agregado', 'OK', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/inventario']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
