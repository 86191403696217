<div fxLayout="row" fxLayoutAlign="start center">

    <mat-icon class="handle mr-16" ngxDragHandle fxFlex="0 1 auto" fxHide.xs>
        drag_handle
    </mat-icon>

    <!--
    <mat-checkbox [(ngModel)]="selected" (ngModelChange)="onSelectedChange()"
                  class="mr-16" fxFlex="0 1 auto" (click)="$event.stopPropagation()">
    </mat-checkbox>
    -->

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

        <div class="info" fxFlex fxFlexLayout="column">

            <div class="title">
                {{pregunta.id}} - {{pregunta.questions_groups_id}} - {{pregunta.description}}
            </div>

            <div class="notes">
                {{pregunta.type | questionsType}}
            </div>

        </div>

        <div class="actions" fxLayout="row" fxLayoutAlign="start center">

        </div>


    </div>

</div>
