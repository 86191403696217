export const locale = {
    lang: 'es',
    data: {
        USUARIOS: {
            LIST: {
                TITLE: 'USUARIOS'
            }
        }
    }
};
