<div *ngIf="!currentPregunta" fxLayout="column" fxLayoutAlign="center center" fxFlex>
    <mat-icon class="s-120 mb-12 select-preguntas-icon hint-text"
              [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">check_box
    </mat-icon>
    <span class="hint-text mat-h1 select-preguntas-text" [@animate]="{value:'*',params:{delay:'400ms'}}">
        Selecciona un pregunta
    </span>
</div>

<div *ngIf="currentPregunta">

    <div class="preguntas-header" fxLayout="row" fxLayoutAlign="flex-end center">

        <!--
        <button mat-button class="toggle-complete-button" (click)="toggleCompleted($event)"
                aria-label="Toggle completed" fxFlex="0 1 auto">
            <mat-icon *ngIf="pregunta.completed">check_box</mat-icon>
            <mat-icon *ngIf="!pregunta.completed">check_box_outline_blank</mat-icon>
            <span class="ml-8">Mark as Done</span>
        </button>
        -->

        <div class="actions" fxLayout="row" fxLayoutAlign="end center" >

            <div class="mr-8" *ngIf="formType === 'new'">
                <button mat-raised-button
                        class="mat-accent add-todo-button w-100-p"
                        (click)="addPregunta()"
                        [disabled]="preguntaForm.invalid"
                        aria-label="Guardar">
                    Guardar nueva pregunta
                </button>
            </div>

            <div class="mr-8" *ngIf="formType === 'edit'">
                <button mat-raised-button
                        color="accent"
                        class="mat-accent"
                        (click)="savePregunta()"
                        [disabled]="preguntaForm.invalid">
                    Guardar cambios
                </button>
            </div>

            <button mat-mini-fab *ngIf="formType === 'edit'" class="mr-8" color="warn" title="Borrar" aria-label="Borrar" (click)="deleteItem(currentPregunta.id)">
                <mat-icon class="active-icon " >delete</mat-icon>
            </button>

        </div>
    </div>

    <div class="preguntas-content">

        <form [formGroup]="preguntaForm" name="preguntaForm" fxLayout="column" >

            <div class="h2 mb-24">Datos de la pregunta</div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="outline" class="" fxFlex="50" >
                    <mat-label>Descripción</mat-label>
                    <input matInput #descriptionInput name="description" formControlName="description" maxlength="255" required>
                    <mat-error >
                        Descripción es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Tipo de campo</mat-label>
                    <mat-select name="type" formControlName="type" required>
                        <mat-option [value]="1">
                            Checkbox
                        </mat-option>
                        <mat-option [value]="2">
                            Foto
                        </mat-option>
                        <mat-option [value]="4">
                            Audio
                        </mat-option>
                    </mat-select>
                    <mat-error >
                        El tipo de campo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="mb-32" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <div appearance="outline" fxFlex="50" class="pl-4">
                    <mat-checkbox name="required" formControlName="required" >Información, ¿Es requerida?</mat-checkbox>
                </div>

                <div appearance="outline" fxFlex="50" class="pl-4">
                    <mat-checkbox class="example-margin" name="comments_visible" formControlName="comments_visible" >Comentario, ¿Es visible?</mat-checkbox>
                </div>

                <div appearance="outline" fxFlex="50" class="pl-4">
                    <mat-checkbox class="example-margin" name="comments_required" formControlName="comments_required" >Comentario, ¿Es requerido</mat-checkbox>
                </div>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Etiqueta para comentario</mat-label>
                    <input matInput name="comments_label" formControlName="comments_label" maxlength="255" required>
                    <mat-error >
                        Etiqueta para comentario es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Tipo de comentario</mat-label>
                    <mat-select name="comments_type" formControlName="comments_type" required>
                        <mat-option [value]="1">
                            Abierto
                        </mat-option>
                        <mat-option [value]="2">
                            Código de barras
                        </mat-option>
                        <mat-option [value]="4">
                            Código QR
                        </mat-option>
                    </mat-select>
                    <mat-error >
                        El tipo de comentario es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>

            </div>

        </form>

    </div>
</div>
