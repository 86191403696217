import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Question } from 'app/models/question.model';
import { GLOBAL } from '../../global/global';
import { LoginService } from 'app/main/login/login.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ',
    })
};

@Injectable()
export class PreguntasService implements Resolve<any>
{
    preguntas: Question[];
    selectedTodos: Question[];
    currentPregunta: Question;
    searchText: string;
    filters: any[];
    routeParams: any;
    currentIdService: number;
    currentIdGroup: number;

    onPreguntasChanged: BehaviorSubject<any>;
    onSelectedTodosChanged: BehaviorSubject<any>;
    onCurrentPreguntaChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onTagsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    onNewTodoClicked: Subject<any>;

    onTiposServiciosChanged: BehaviorSubject<any>;
    onGrupoPreguntaChanged: BehaviorSubject<any>;

    private urlAPI: string;
    private moduleAPI: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Location} _location
     */
    constructor(
        private _httpClient: HttpClient,
        private _location: Location,
        private _loginService: LoginService
    )
    {
        // Set the defaults
        this.selectedTodos = [];
        this.searchText = '';
        this.onPreguntasChanged = new BehaviorSubject([]);
        this.onSelectedTodosChanged = new BehaviorSubject([]);
        this.onCurrentPreguntaChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onTagsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
        this.onNewTodoClicked = new Subject();
        this.onTiposServiciosChanged = new BehaviorSubject('');
        this.onGrupoPreguntaChanged = new BehaviorSubject(0);
        this.urlAPI = GLOBAL.url;
        this.moduleAPI = 'questions';

        //OBtiene tipos de servicio.
        //this.getTiposServicio();

    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        // Actualiza los parámetros
        this.currentIdService = (this.routeParams.filterService) ? this.routeParams.filterService : 0;
        this.currentIdGroup = (this.routeParams.filterGroup) ? this.routeParams.filterGroup : 0;

        // Devuelve información
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getTiposServicio(),
                // this.getPreguntas()

            ]).then(
                () => {

                    /*
                    if ( this.routeParams.todoId )
                    {
                        this.setCurrentPregunta(this.routeParams.todoId);
                    }
                    else
                    {
                        this.setCurrentPregunta(null);
                    }
                    */

                    this.onSearchTextChanged.subscribe(searchText => {
                        if ( searchText !== '' )
                        {
                            this.searchText = searchText;
                            this.getPreguntas();
                        }
                        else
                        {
                            this.searchText = searchText;
                            this.getPreguntas();
                        }
                    });
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get all filters
     *
     * @returns {Promise<any>}
     */
    getFilters(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/todo-filters')
                .subscribe((response: any) => {
                    this.filters = response;
                    this.onFiltersChanged.next(this.filters);
                    resolve(this.filters);
                }, reject);
        });
    }

    /**
     * Obtiene todos los tipos de servicio
     *
     * @returns {Promise<any>}
     */
    getTiposServicio(): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'servicetypes', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onTiposServiciosChanged.next(response.data.tiposservicio);
                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Obtiene todos los grupos de preguntas
     *
     * @returns {Promise<any>}
     */
    getGruposPreguntaByService(idService): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'questiongroups/byservice/' + idService, httpOptions)
                .subscribe(
                    (response: any) => {

                        //Quita las preguntas
                        this.onPreguntasChanged.next(null);

                        //Quita un grupo seleccionado por default
                        this.onGrupoPreguntaChanged.next(this.currentIdGroup);

                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Get todos
     *
     * @returns {Promise<Pregunta[]>}
     */
    getPreguntas(): Promise<Question[]>
    {
        /*
        if ( this.routeParams.tagHandle )
        {
            return this.getTodosByTag(this.routeParams.tagHandle);
        }
        */

        if ( this.currentIdGroup > 0 )
        {
            //Quita un grupo seleccionado por default
            this.onCurrentPreguntaChanged.next([null, null]);
            this.onGrupoPreguntaChanged.next(this.currentIdGroup);

            return this.getPreguntasByGroup(this.currentIdGroup);
        }

        // return this.getPreguntasByParams(this.routeParams);
    }

    /**
     * Get todos by filter
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getPreguntasByGroup(handle): Promise<Question[]>
    {

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'questions/bygroup/' + handle, httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            this.preguntas = response.data.questions.map(datPregunta => {
                                return new Question(datPregunta);
                            });

                            this.preguntas = FuseUtils.filterArrayByString(this.preguntas, this.searchText);
                        } else {
                            this.preguntas = null;
                        }

                        this.onPreguntasChanged.next(this.preguntas);

                        resolve(this.preguntas);

                    }, reject);
        });

    }

    /**
     * Toggle selected todo by id
     *
     * @param id
     */
    toggleSelectedTodo(id): void
    {
        // First, check if we already have that todo as selected...
        if ( this.selectedTodos.length > 0 )
        {
            for ( const todo of this.selectedTodos )
            {
                // ...delete the selected todo
                if ( todo.id === id )
                {
                    const index = this.selectedTodos.indexOf(todo);

                    if ( index !== -1 )
                    {
                        this.selectedTodos.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedTodosChanged.next(this.selectedTodos);

                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedTodos.push(
            this.preguntas.find(todo => {
                return todo.id === id;
            })
        );

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedTodos.length > 0 )
        {
            this.deselectTodos();
        }
        else
        {
            this.selectTodos();
        }

    }

    /**
     * Select todos
     *
     * @param filterParameter
     * @param filterValue
     */
    selectTodos(filterParameter?, filterValue?): void
    {
        this.selectedTodos = [];

        // If there is no filter, select all todos
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedTodos = this.preguntas;
        }
        else
        {
            this.selectedTodos.push(...
                this.preguntas.filter(todo => {
                    return todo[filterParameter] === filterValue;
                })
            );
        }

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Deselect todos
     */
    deselectTodos(): void
    {
        this.selectedTodos = [];

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Set current question
     *
     * @param id
     */
    setCurrentPregunta(id): void
    {

        this.currentPregunta = this.preguntas.find(todo => {
            return todo.id === id;
        });

        this.onCurrentPreguntaChanged.next([this.currentPregunta, 'edit']);

        /*
        const filterGroup = this.routeParams.filterGroup;

        if ( filterGroup )
        {
            this._location.go('preguntas/filter/' + filterGroup + '/' + id);
        }
        else
        {
            this._location.go('preguntas/all/' + id);
        }
        */
    }

    /**
     * Set current question
     *
     * @param id
     */
    setNewPregunta(): void
    {
        this.currentPregunta = new Question();
        this.onCurrentPreguntaChanged.next([this.currentPregunta, 'new']);
        // const filterGroup = this.routeParams.filterGroup;

        // TODO: Cambiar el location.go
    }

    /**
     * Update the todo
     *
     * @param todo
     * @returns {Promise<any>}
     */
    updateTodo(todo): any
    {
        return new Promise((resolve, reject) => {

            this._httpClient.post('api/todo-todos/' + todo.id, {...todo})
                .subscribe(response => {

                    this.getPreguntas().then(todos => {

                        resolve(todos);

                    }, reject);
                });
        });
    }

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    activate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questions/activate/' + id + '/' + type, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    delete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'questions/' + id, httpOptions).subscribe(
                (response: any) => {
                    this.getPreguntas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Update data
     * @param token
     * @param car
     */
    update(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                question: data
            }
        };
        const myParam = JSON.stringify(myData);

        // TODO: Falta obtener la imágen de referencia en caso de existir.

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questions/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    this.getPreguntas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    create(data): Promise<any>{
        data.questions_groups_id = this.currentIdGroup;
        const json = JSON.stringify(data);
        const myData = {
            data: {
                question: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'questions', myParam, httpOptions).subscribe(
                (response: any) => {
                    /*
                    if ( this.routeParams.filterGroup )
                    {
                        this._location.go('preguntas/filter/' + this.routeParams.filterGroup);
                    }
                    else
                    {
                        this._location.go('preguntas/all/');
                    }*/
                    this.getPreguntas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get data of item
     * @param token
     * @param car
     */
    getOne(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'questions/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Actualiza el Param Id
     * @param idService
     */
    setCurrentIdService(idService): void {
        this.currentIdService = idService;
    }

    /**
     * Actualiza el Param Id
     *
     * @param idGroup
     */
    setCurrentIdGroup(idGroup): void {
        this.currentIdGroup = idGroup;
    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    grupoCreate(data): Promise<any>{
        data.service_type_id = this.currentIdService;
        const json = JSON.stringify(data);
        const myData = {
            data: {
                questiongroup: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'questiongroups', myParam, httpOptions).subscribe(
                (response: any) => {
                    /*
                     if ( this.routeParams.filterGroup )
                     {
                     this._location.go('preguntas/filter/' + this.routeParams.filterGroup);
                     }
                     else
                     {
                     this._location.go('preguntas/all/');
                     }*/
                    this.getPreguntas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Update data
     * @param token
     * @param car
     */
    grupoUpdate(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                questiongroup: data
            }
        };
        const myParam = JSON.stringify(myData);

        // TODO: Falta obtener la imágen de referencia en caso de existir.

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questiongroups/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    this.getPreguntas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    grupoDelete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'questiongroups/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    grupoActivate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        let httpOptions2 = httpOptions;
        httpOptions2.headers = httpOptions2.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questiongroups/activate/' + id + '/' + type, myParam, httpOptions2).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
}
