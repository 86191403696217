

<div id="page-cuadrillasempleados" >

    <!-- CENTER -->
    <div class="center">

        <div class="content-card">
            <div class="toolbar " fxLayout="row" fxLayoutAlign="start center">

                <div fxHide.gt-lg>
                    <button mat-icon-button (click)="regresarListadoCuadrilla()">
                        <mat-icon class="secondary-text">arrow_back</mat-icon>
                    </button>
                </div>

                <div class="logo p-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"> <strong>Cuadrilla del día</strong> {{currentCuadrilla.dt_team | date}} de {{currentCuadrilla.user.name}} </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" >
                    <button mat-raised-button color="primary" aria-label="Asignar empleado" title="Asignar empleado" (click)="nuevoEmpleadoCuadrilla()">Asignar empleado</button>
                </div>

            </div>

            <mat-table class="cuadrillas-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="staff.id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.staff.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="staff.name" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Empleado</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.staff.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="comments">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Comentarios</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="">{{ data.comments }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="warn" title="Borrar" aria-label="Borrar" (click)="deleteEmpleadoCuadrilla(data.id)">
                                <mat-icon class="active-icon " >delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Rows definition -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         matRipple >
                </mat-row>

            </mat-table>

        </div>
    </div>
</div>