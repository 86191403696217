import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { QuestionGroup } from 'app/models/question-group.model';

@Component({
    selector     : 'grupo-form-dialog',
    templateUrl  : './grupo-form.component.html',
    styleUrls    : ['./grupo-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GrupoFormDialogComponent
{
    serviceId: number;
    action: string;
    dataGrupo: QuestionGroup;
    dataGrupoForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<GrupoFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.serviceId = _data.serviceId
            this.dialogTitle = 'Modificar Grupo';
            this.dataGrupo = _data.dataGrupo;
        }
        else
        {
            this.dialogTitle = 'Nuevo grupo';
            this.dataGrupo = new QuestionGroup();
        }

        this.dataGrupoForm = this.createGroupForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup
    {
        return this._formBuilder.group({
            id          : [this.dataGrupo.id],
            name        : [this.dataGrupo.name],
            description : [this.dataGrupo.description],
            priority    : [this.dataGrupo.priority]
        });
    }


}
