
<div id="page-cuadrillas-lista" >

    <!-- CENTER -->
    <div class="center">

        <div class="content-card">
            <mat-table class="cuadrillas-table"
                       #table [dataSource]="dataSource"
                       matSort
                       fusePerfectScrollbar
                       [@animateStagger]="{value:'50'}"
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user.name" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Usuario</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.user.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_record">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh.Registro</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.dt_record | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_team">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh.Cuadrilla</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.dt_team | date }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_code">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Núm. Económico</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.data_code}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="staffs.count">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Empleados</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate"> </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Ver empleados" aria-label="Ver empleados" (click)="verEmpleados(data.id)">
                                <mat-icon class="active-icon " >person</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Rows definition -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         (click)="verMasInfo(data)"
                         matRipple
                        >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
</div>