import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { Truck } from '../../../models/truck.model';
import { GruasService } from 'app/main/gruas/gruas.service';
import { GLOBAL } from '../../../global/global';

@Component({
    selector: 'app-gruas-add',
    templateUrl: './gruas-add.component.html',
    styleUrls: ['./gruas-add.component.scss'],
    providers: [GruasService]
})
export class GruasAddComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public dataGrua: Truck;
    private titleModule: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _gruasService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _gruasService: GruasService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();
        this.titleModule = 'Grúa';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {
        this.dataGrua = new Truck();
        // Reactive Form
        this.form = this.createDataForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataGrua.id],
            name: [this.dataGrua.name, [Validators.required]],
            code: [this.dataGrua.code, [Validators.required]],
            phone_number: [this.dataGrua.phone_number, [Validators.required]]
        });

    }

    saveGrua(): void {
        this._gruasService.create(this.form.value)
            .then(() => {
                    this._matSnackBar.open(this.titleModule + ' agregada', 'OK', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/gruas']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
