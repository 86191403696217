// Angular y tema
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { fuseAnimations } from '@fuse/animations';
import { takeUntil } from 'rxjs/internal/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Módulo
import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';
import { TeamStaff } from 'app/models/team-staff.model';
import {Team} from "../../../../models/team.model";
import {EmpleadosAddDialogComponent} from "../add/empleados-add.component";

@Component({
    selector: 'app-empleados-lista',
    templateUrl: './empleados-lista.component.html',
    styleUrls: ['./empleados-lista.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],

})

export class EmpleadosListaComponent implements OnInit, OnDestroy {

    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    currentCuadrilla: Team;
    dataSource: TeamStaff[];
    displayedColumns = ['staff.id', 'staff.name', 'comments', 'acciones'];

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    private titleModule: string;

    /**
     * Constructor
     *
     * @param _cuadrillasService
     * @param _fuseTranslationLoaderService
     * @param _matSnackBar
     * @param _router
     * @param _matDialog
     */
    constructor(
        private _cuadrillasService: CuadrillasService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this.titleModule = 'Empleado';
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.currentCuadrilla = this._cuadrillasService.currentCuadrilla;
        this.dataSource = this.currentCuadrilla.staffs;

        this._cuadrillasService.onCurrentCuadrillaEmpleadoChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentTeam) => {
                if ( !currentTeam)
                {
                    this.currentCuadrilla = null;
                    this.dataSource = null;
                }
                else
                {
                    this.currentCuadrilla = currentTeam;
                    this.dataSource = this.currentCuadrilla.staffs;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    verEmpleados(idCuadrilla): void {
        this._cuadrillasService.setShowEmpleados();
    }

    /**
     *
     * @param actType
     * @param empleadoId
     */
    deleteEmpleadoCuadrilla(empleadoId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea eliminar al ' + this.titleModule + ' de la cuadrilla?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._cuadrillasService.empleadosCuadrillaDelete(empleadoId, this.currentCuadrilla.id)
                        .then((response: any) => {
                            if (response.code == 200)
                            {
                                this._matSnackBar.open(this.titleModule + ' eliminado', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(response.message, 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }

                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }

    regresarListadoCuadrilla(): void {

        this._cuadrillasService.setCurrentCuadrilla(this.currentCuadrilla.id);
        this._cuadrillasService.setShowCuadrillas();

    }

    nuevoEmpleadoCuadrilla(): void {
        this.dialogRef = this._matDialog.open(EmpleadosAddDialogComponent, {
            panelClass: 'empleados-add-dialog',
            data      : {
                teamId: this.currentCuadrilla.id,
                action : 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._cuadrillasService.empleadosCuadrillaCreate(response.getRawValue())
                    .then((response) => {
                        if (Number(response.code) === 200){
                            this._matSnackBar.open('Empleado agregado', 'OK', {
                                verticalPosition: 'top',
                                duration        : 2000
                            });
                        } else {
                            this._matSnackBar.open(response.message, '', {
                                verticalPosition: 'top',
                                duration: 3000
                            });
                        }

                    })
                    .catch((error: any) => {
                        this._matSnackBar.open('Error en actualización', 'Error', {
                            verticalPosition: 'top',
                            duration        : 2000
                        });
                    });

            });
    }


}
