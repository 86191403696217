<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form">

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Fecha de orden de trabajo</mat-label>
                    <input matInput [matDatepicker]="picker"
                           formControlName="dateIni"
                           (dateChange)="addEventDatePicker($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month" ></mat-datepicker>
                    <mat-error >
                        Fecha de orden de trabajo es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" floatLabel="never" class="cssSelectFilter pl-4" style="width: 100%;">
                    <mat-label>Tipo de Servicio</mat-label>
                    <mat-select class="account-selection" placeholder="Seleccione un Tipo de Servicio"
                                name="selectedTipoServicio" formControlName="selectedTipoServicio">
                        <mat-option *ngFor="let _dataTiposServicio of dataTiposServicio" [value]="_dataTiposServicio">
                            {{_dataTiposServicio.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error >
                        Tipo de servicio es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex floatLabel="never" class="cssSelectFilter" style="width: 100%;">
                    <mat-label>Operador</mat-label>
                    <mat-select class="account-selection" placeholder="Seleccione un operador"
                                name="selectedOperador" formControlName="selectedOperador">
                        <mat-option *ngFor="let _dataOperador of dataOperadores" [value]="_dataOperador">
                            {{_dataOperador.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error >
                        Operador es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="title mat-subheading-2 mb-0">Puntos de revisión</div>
            <div fxLayout="column" fxLayoutAlign="start normal" fusePerfectScrollbar >
                <mat-selection-list #points style="width: 100%;">
                    <mat-list-option *ngFor="let point of pointsList" [value]="point">
                        <mat-icon matListIcon>center_focus_strong</mat-icon>
                        {{point.data_code}} - {{point.data_type}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <p>
                    Puntos seleccionados: {{points.selectedOptions.selected.length}}
                </p>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button
                mat-raised-button
                class="mat-accent"
                [disabled]="!form.valid || points.selectedOptions.selected.length <= 0"
                (click)="guardarDatos(points.selectedOptions.selected)"
                aria-label="Guardar">
            Guardar
        </button>
    </div>
</div>
