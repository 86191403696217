import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Question } from 'app/models/question.model';

@Component({
    selector     : 'detalle-pregunta-dialog',
    templateUrl  : './detalle-pregunta.component.html',
    styleUrls    : ['./detalle-pregunta.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DetallePreguntaDialogComponent
{
    preguntaId: number;
    action: string;
    dataPregunta: Question;
    dataPreguntaForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<DetallePreguntaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        this.preguntaId = _data.preguntaId;
        this.dialogTitle = 'Pregunta';
        this.dataPregunta = _data.dataPregunta;

        this.dataPreguntaForm = this.createGroupForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup
    {
        return this._formBuilder.group({
            id: [this.dataPregunta.id],
            description: [{
                value: this.dataPregunta.description,
                disabled: true
            }],
            type: [{
                value: this.dataPregunta.type,
                disabled: true
            }],
            required: [{
                value: this.dataPregunta.required,
                disabled: true
            }],
            comments_visible: [{
                value: this.dataPregunta.comments_visible,
                disabled: true
            }],
            comments_required: [{
                value: this.dataPregunta.comments_required,
                disabled: true
            }],
            comments_label: [{
                value: this.dataPregunta.comments_label,
                disabled: true
            }],
            comments_type: [{
                value: this.dataPregunta.comments_type,
                disabled: true
            }],
            reference: [{
                value: this.dataPregunta.reference,
                disabled: true
            }],
        });
    }


}
