export const locale = {
    lang: 'es',
    data: {
        EMPLEADOS: {
            ADD: {
                TITLE: 'NUEVO EMPLEADO'
            }
        }
    }
};
