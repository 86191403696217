export const locale = {
    lang: 'es',
    data: {
        GRUAS: {
            HISTORY: {
                TITLE1: 'UBICACIONES',
                TITLE2: 'GRÚA',
            }
        }
    }
};
