import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionsType'
})
export class QuestionsTypePipe implements PipeTransform {

    transform(value: number): string {
        switch (value) {
            case 1:
                return 'Checkbox';
                break;
            case 2:
                return 'Foto';
                break;
            case 4:
                return 'Audio';
                break;
            default:
                return 'ERR-' + value;
                break;
        }

    }

}
