<div id="page-gruas" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar sidebar_cuadrillas" name="cuadrillas-main-sidebar" position="left" lockedOpen="gt-md">

        <!-- SIDEBAR HEADER -->
        <div fxLayout="column" fxLayoutAlign="center flex-start " class="header accent p-24 pb-4">
            <div fxLayout="row" >
                <a routerLink="/"><mat-icon class="secondary-text s-18">home</mat-icon></a>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <a routerLink="/gruas"><span class="secondary-text">GRUAS</span></a>
            </div>
            <div class="h1">{{'GRUAS.HISTORY.TITLE1' | translate}}</div>

        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{y:'50px'}}">

            <div class="toolbar-action-button-row px-24 py-24" fxLayout="column" fxLayoutAlign="end center" >
                <mat-form-field>
                    <mat-label>Fecha de inicio</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="dateIni">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Fecha de fin</mat-label>
                    <input matInput [matDatepicker]="picker2" [formControl]="dateFin">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 startView="month" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>

                <button (click)="buscarInfo()" mat-raised-button color="primary" aria-label="Buscar" title="Buscar">Buscar</button>
            </div>

        </div>
        <!-- / SIDEBAR CONTENT -->




    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="column" fxLayoutAlign="center start">

                <div class="h1">{{'GRUAS.HISTORY.TITLE2' | translate}}: {{currentGrua.name}}</div>
            </div>
            <!-- / APP TITLE -->



        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="gruas-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_request">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh Busqueda</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.dt_request | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_response" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Fh Respuesta</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.dt_response | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_device">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Información</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p >{{data.data_device}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Ver última ubicación" aria-label="Ver última ubicación" *ngIf="data.geolocation" (click)="verMapa(data)">
                                <mat-icon class="active-icon " >pin_drop</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         matRipple
                        >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
