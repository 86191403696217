import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { OrdenesService } from 'app/main/ordenes/ordenes.service';
import { OrdersChecklist } from '../../../../models/orders-checklist.model';

@Component({
  selector: 'app-pregunta-imagen-dialog',
  templateUrl: './pregunta-imagen-dialog.component.html',
  styleUrls: ['./pregunta-imagen-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreguntaImagenDialogComponent implements OnInit {

    orderChecklistId: number;
    locationId: number;
    action: string;
    dataOrderChecklist: OrdersChecklist;
    dataOrderChecListForm: FormGroup;
    dialogTitle: string;
    lat: number;
    lng: number;
    imageToShow: any;
    @ViewChild('audioOption')
        audioPlayerRef: ElementRef;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<PreguntaImagenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _ordenesService: OrdenesService,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the defaults
        this.dialogTitle = 'Pregunta';
        this.action = _data.action;

        if (this.action === 'imagen'){
            this.orderChecklistId = _data.orderChecklistId;
            this.dataOrderChecklist = _data.dataOrderChecklist;
            this.imageToShow = this.getImageFromService(this.orderChecklistId);
        } else if (this.action === 'audio') {
            this.orderChecklistId = _data.orderChecklistId;
            this.dataOrderChecklist = _data.dataOrderChecklist;
            this.imageToShow = this.getImageFromService(this.orderChecklistId);
        } else {
            matDialogRef.close();
        }

        this.dataOrderChecListForm = this.createGroupForm();
    }

    /**
     * On init
     */
    ngOnInit(): void {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataOrderChecklist.id],
            name: [{
                value: this.dataOrderChecklist.checklist_question.question.description,
                disabled: false
            }],
            geolocation: [this.dataOrderChecklist.geolocation],
            geolocation_lat: [{
                value: this.dataOrderChecklist.geolocation.coordinates[0],
                disabled: false
            }],
            geolocation_long: [{
                value: this.dataOrderChecklist.geolocation.coordinates[0],
                disabled: false
            }],
        });
    }

    getImageFromService(idOrderChecklist: number): void {
        this._ordenesService.getImageOrderCheckList(idOrderChecklist)
            .then((data) => {
                if (this.dataOrderChecklist.checklist_question.question.type === 2) {
                    this.createImageFromBlob(data);
                } else {
                    const blob = new Blob([data], {type: 'audio/mpeg'});
                    this.imageToShow = URL.createObjectURL(blob);
                    this.audioPlayerRef.nativeElement.src = this.imageToShow;
                    this.audioPlayerRef.nativeElement.play();
                }
            })
            .catch((error: any) => {
            });

    }

    createImageFromBlob(image: Blob): void {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

}
