import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule} from '@angular/material/table';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';

import { AgmCoreModule } from '@agm/core';

import { TranslateModule} from '@ngx-translate/core';

import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';
import { CuadrillasPrincipalComponent } from 'app/main/cuadrillas/principal/cuadrillas-principal.component';
import { CuadrillasSidebarComponent } from 'app/main/cuadrillas/sidebars/cuadrillas-sidebar.component';
import { CuadrillasListaComponent } from 'app/main/cuadrillas/listado/cuadrillas-lista.component';
import { CuadrillasDetalleDialogComponent } from 'app/main/cuadrillas/detalle/cuadrillas-detalle.component';
import { EmpleadosListaComponent } from 'app/main/cuadrillas/empleados/listado/empleados-lista.component';
import { EmpleadosAddDialogComponent } from 'app/main/cuadrillas/empleados/add/empleados-add.component';


const routes: Routes = [
    {
        path     : 'cuadrillas',
        component: CuadrillasPrincipalComponent,
        resolve  : {
            todo: CuadrillasService
        }
    },
];

@NgModule({
    declarations: [
        CuadrillasPrincipalComponent,
        CuadrillasSidebarComponent,
        CuadrillasListaComponent,
        CuadrillasDetalleDialogComponent,
        EmpleadosListaComponent,
        EmpleadosAddDialogComponent

    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatRadioModule,
        MatChipsModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,

        NgxDnDModule,

        FuseSharedModule,
        FuseSidebarModule,

        TranslateModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

    ],
    providers   : [
        CuadrillasService
    ],
    /*
    entryComponents: [
        GrupoFormDialogComponent
    ]*/
})
export class CuadrillasModule
{
}
