<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-8" fxLayout="column" fxLayoutAlign="center center">
            <div class="contact-name mt-8">{{user.name}}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="userForm" name="userForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Password</mat-label>
                    <mat-icon matSuffix class="secondary-text">lock_open</mat-icon>
                    <input name="password" formControlName="password" matInput maxlength="22" required>
                    <mat-error *ngIf="userForm.get('password').hasError('maxlength') && !userForm.get('password').hasError('required')">
                        Proporciona un password <strong>válido</strong>
                    </mat-error>
                    <mat-error *ngIf="userForm.get('password').hasError('required')">
                        Password es <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action === 'edit'"
                mat-button
                class="save-button"
                (click)="matDialogRef.close(['save',userForm])"
                [disabled]="userForm.invalid"
                aria-label="GUARDAR">
            Guardar
        </button>

    </div>
</div>
