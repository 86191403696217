import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { Question } from 'app/models/question.model';
import { PreguntasService } from 'app/main/preguntas/preguntas.service';
import { QuestionGroup } from "../../../models/question-group.model";

@Component({
    selector     : 'app-principal',
    templateUrl  : './principal.component.html',
    styleUrls    : ['./principal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class PrincipalComponent implements OnInit, OnDestroy
{
    hasSelectedTodos: boolean;
    isIndeterminate: boolean;
    filters: any[];
    tags: any[];
    searchInput: FormControl;
    currentPregunta: Question;
    currentGrupo: number;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _fuseSidebarService
     * @param _preguntasService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _preguntasService: PreguntasService
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');
        this.currentGrupo = 0;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._preguntasService.onSearchTextChanged.next(searchText);
            });


        this._preguntasService.onCurrentPreguntaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([currentPregunta, formType]) => {
                if ( !currentPregunta)
                {
                    this.currentPregunta = null;
                }
                else
                {
                    this.currentPregunta = currentPregunta;
                }
            });

        this._preguntasService.onGrupoPreguntaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(dataId => {
                this.currentGrupo = dataId;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Deselect current todo
     */
    deselectCurrentPregunta(): void
    {
        this._preguntasService.onCurrentPreguntaChanged.next([null, null]);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    /**
     *
     */
    nuevaPregunta(): void
    {
        this._preguntasService.setNewPregunta();
    }

}
