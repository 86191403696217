export const locale = {
    lang: 'es',
    data: {
        GASOLINERAS: {
            LIST: {
                TITLE: 'GASOLINERAS'
            }
        }
    }
};
