export var GLOBAL = {
    // url: 'http://127.0.0.1:8088/api/v1/web/',
    // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA2ZTQ5NzMzMTI5N2E4MGVhM2Q4YjAxZmRlYjhiYTI0ZTBhNTAzYzhmYjdjMTMxZjJiZDlkZjlmOTg2ZDRmYjZkY2JjMmZmZjQwMjZmNjVhIn0.eyJhdWQiOiIxIiwianRpIjoiMDZlNDk3MzMxMjk3YTgwZWEzZDhiMDFmZGViOGJhMjRlMGE1MDNjOGZiN2MxMzFmMmJkOWRmOWY5ODZkNGZiNmRjYmMyZmZmNDAyNmY2NWEiLCJpYXQiOjE1OTY2NTQyNDMsIm5iZiI6MTU5NjY1NDI0MywiZXhwIjoxNTk3MjU5MDQzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.qKDohlVAytHOIjmG45elBWeXUj0TFX-dzInmXeJg0IHXFs0MXjMXHQMLlOWQmLmCmKuO4xRDZs98Njp7wi0P8WVjgSNO4i-6lr1CwBGCUcB-ypluUan-Z3yOzTQBV6QGXXy27RF6ApqcBNUfsKPzD6Zc2aDGDbufNVmfi0kMFlQuzkgPx-QstI5Esu2pj4OPt4YV8nzqDBM4ZaydDsJlFBURAmWqS6AKEjAdyBa0eqLLHeBdufehrOGTdrBtN2YYFOypY5PCXVVlaHK26xjfq-cdyDfiHcjj5Dg8d-4JAa12r37eV3kug3TMDhsR8XLW9QmTOxAsSlIUCUfDtHdItfLvJ3XGlJcmUuqTVQ1OnjriKHH5QaA1l4AX4hqvnv8Q9RltRantXwxd0rQXAqZU9MgbK9flqlcsyXyYfsVlenK7kgAYgjdX9eIG1wg-JUQxyI9Lr7l_bX8UHa-hembSeA_cK_gd0F-Bn0QQda9ESvUe9_jJUGbvi1hf5lmW5P_T4Ztw_80YObzpWGGIedq5HG-OadBout-WPUk7sDN9m-oUOwHfwvUa5GuZ5Ye8lv5vO5rG7emFHkW1Lca0ZeypM0t5VbkLtRn1BrsnSjGHs-q-K3cYYsMniwv2bzrmJTO_EOAUez84N05IJrQIrdY8s1CCllUB5YwDx22IqIiHSj0',

    url: 'http://trapp.arteika.com/API/public/api/v1/web/',
    //url: 'http://127.0.0.1:8088/api/v1/web/',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijk4YjJhZDZhNGY2ZGQ3MTQyMTczMzM3YjY1MjIyNGEyYzA1OThjMjVhYTc2OWEyMGRjN2IxZGQyMjQ1ZDIwMjFhMTdiM2NiYTM1YTk4YmQyIn0.eyJhdWQiOiIxIiwianRpIjoiOThiMmFkNmE0ZjZkZDcxNDIxNzMzMzdiNjUyMjI0YTJjMDU5OGMyNWFhNzY5YTIwZGM3YjFkZDIyNDVkMjAyMWExN2IzY2JhMzVhOThiZDIiLCJpYXQiOjE1OTY3MzA0NjksIm5iZiI6MTU5NjczMDQ2OSwiZXhwIjoxNTk3MzM1MjY5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.QSUYwL2gG9st9oem74ApBcV-l9g1wMzPtlHOD5s2LfBHIsuM6pjDaf9051Yz9sFzItTnz767U4OmgeyKM3053CznO4oEejk_OOJw4IiZb_fNA0bETZUdkjKe9iEydP0PG9_uZ4-vmw8DYCJCocign8_Ke2sABSSKNr72Ib80peXkoOcaaV-sAd4dGgvKHNlHTmyP5JvOFRmegC8DG7lnnxhPv073GjFpfoCsgDAAPKW-E9X_QtJ0p7msdgIFS_gvXFjZ0_31GIv7H34GiV2MCaR2v0ICYrKf9lWdpFcQ0R5uGd_8CmxqsLno-qdPVSUNFFBoR6eNoK35Vq46wAuBEZ9ymMwQRinKGpHLDpqOAh9iYN50IdwZMYHznWzdV1uGX-Oj_t8JZCoSYfzQAOfbde8i3TtjCQ0FEIm5IzcEguMUxXDNufBlWrjiYd6aGSG1NAhY9iiku2UA0R1qiAkySpzRdqI28up4WWgx5rmcZzdOcJsO3sQIYqnvzA6Dl_4Tx8ahQNcejbKei11F54rbCuo81JNm4m5XK1uEryg93Sw_gIu35h88HTmiTYNfu5ZnvIAc0g3zzNfYjOR8j_ceKBltG4c5bvWxyTXJK-5WAPcB749kZIiSWJ1OG4pFfhcfrrre62fT3Mvs0cb0_IhpHDRZVxqGGbaAkmIS3g6n314',

    validators: {
        coordinates: '-?[0-9]{1,3}(?:\.[0-9]{2,10})'
    }
};