import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { GasStation } from '../../../models/gas-station.model';
import { GasolinerasService } from 'app/main/gasolineras/gasolineras.service';
import {GLOBAL} from "../../../global/global";

@Component({
    selector: 'app-gasolineras-edit',
    templateUrl: './gasolineras-edit.component.html',
    styleUrls: ['./gasolineras-edit.component.scss'],
    providers: [GasolinerasService]
})
export class GasolinerasEditComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public dataGasStation: GasStation;
    private paramId: number;
    private titleModule: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _gasolinerasService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _gasolinerasService: GasolinerasService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();
        this.titleModule = 'Gasolinera';

        //Parametros
        this._route.params.subscribe((params: Params) => {
            this.paramId = params.id;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {

        this.dataGasStation = new GasStation();
        this.form = this.createDataForm();

        //Obtiene el usuario
        this._gasolinerasService.getOne(this.paramId)
            .then((response: any) => {
                this.dataGasStation = new GasStation(response.data.gasstation);
                this.form = this.createDataForm();
            })
            .catch((error: any) => {
                this._matSnackBar.open('Problema con ' + this.titleModule, 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                this._router.navigate(['/gasolineras']);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataGasStation.id],
            name: [this.dataGasStation.name, [Validators.required]],
            code: [this.dataGasStation.code, [Validators.required]],
            schedule: [this.dataGasStation.schedule, [Validators.required]],
            direction: [this.dataGasStation.direction, [Validators.required]],
            geolocation_lat: [this.dataGasStation.geolocation.coordinates[0], [Validators.required, Validators.pattern(GLOBAL.validators.coordinates)]],
            geolocation_long: [this.dataGasStation.geolocation.coordinates[1], [Validators.required, Validators.pattern(GLOBAL.validators.coordinates)]],
            active: [this.dataGasStation.active]
        });
    }

    saveGasolineras(): void {
        this._gasolinerasService.update(this.form.value)
            .then(() => {
                    this._matSnackBar.open(this.titleModule + ' modificada', '', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/gasolineras']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
