export class QuestionGroup {

    id: number;
    service_type_id: number;
    name: string;
    description: string;
    active: number;
    priority: number;
    createdAt: any;
    updatedAt: any;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.service_type_id = data.service_type_id || 0;
        this.name = data.name || '';
        this.description = data.description || '';
        this.active = data.active || 0;
        this.priority= data.priority || 999;
        this.createdAt = data.createdAt || null;
        this.updatedAt = data.updatedAt || null;
    }
}