import { NgModule } from '@angular/core';

import { QuestionsTypePipe } from '../../models/pipes/questions-type.pipe';

@NgModule({
    imports: [

    ],
    declarations: [
        QuestionsTypePipe
    ],
    providers: [
    ],
    exports: [
        QuestionsTypePipe,
    ]
})
export class SharedModule {}
