import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router, ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

import { FuseUtils } from '@fuse/utils';
import { fuseAnimations } from '@fuse/animations';

import { Question } from 'app/models/question.model';
import { PreguntasService } from 'app/main/preguntas/preguntas.service';

@Component({
    selector     : 'preguntas-details',
    templateUrl  : './preguntas-details.component.html',
    styleUrls    : ['./preguntas-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class PreguntasDetailsComponent implements OnInit, OnDestroy
{

    currentPregunta: Question;
    formType: string;
    preguntaForm: FormGroup;

    @ViewChild('descriptionInput')
    descriptionInputField;
    routeParams: any;

    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    titleModule: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _preguntasService
     * @param _formBuilder
     */
    constructor(
        private _preguntasService: PreguntasService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _matDialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.routeParams = _activatedRoute.snapshot.params;
        this.titleModule = 'Pregunta';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to update the current
        this._preguntasService.onCurrentPreguntaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([pregunta, formType]) => {

                this.formType = formType;
                if ( pregunta ) {
                    if (formType === 'edit') {
                        this.currentPregunta = pregunta;
                    } else {
                        this.currentPregunta = new Question();
                    }
                    this.preguntaForm = this.createPreguntaForm();
                    this.focusTitleField();
                }

            });

        /*
        // Subscribe to update on tag change
        this._preguntasService.onNewTodoClicked
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentPregunta = new Question({});
                this.currentPregunta.id = 0;
                this.formType = 'new';
                this.preguntaForm = this.createPreguntaForm();
                this.focusTitleField();
                this._preguntasService.onCurrentPreguntaChanged.next([this.currentPregunta, 'new']);
            });
            */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Focus title field
     */
    focusTitleField(): void
    {
        setTimeout(() => {
            this.descriptionInputField.nativeElement.focus();
        });
    }

    /**
     * Create todo form
     *
     * @returns {FormGroup}
     */
    createPreguntaForm(): FormGroup
    {
        return this._formBuilder.group({
            id                  : [this.currentPregunta.id],
            questions_groups_id : [this.currentPregunta.questions_groups_id, [Validators.required]],
            description         : [this.currentPregunta.description, [Validators.required]],
            type                : [this.currentPregunta.type, [Validators.required]],
            required            : [this.currentPregunta.required],
            comments_visible    : [(this.currentPregunta.comments_visible == null) ? 0 : this.currentPregunta.comments_visible],
            comments_required   : [(this.currentPregunta.comments_required == null) ? 0 : this.currentPregunta.comments_required],
            comments_label      : [(this.currentPregunta.comments_label == null) ? 0 : this.currentPregunta.comments_label ],
            comments_type       : [(this.currentPregunta.comments_type == null) ? 0 : this.currentPregunta.comments_type ],
            reference           : [this.currentPregunta.reference]
        });
    }

    /**
     * Deselect current todo
     */
    deselectCurrentPregunta(): void
    {
        this._preguntasService.onCurrentPreguntaChanged.next([null, null]);
    }

    /**
     * Add todo
     */
    addPregunta(): void
    {
        if (this._preguntasService.currentIdService > 0) {
            this._preguntasService.create(this.preguntaForm.value)
                .then(() => {
                    this._matSnackBar.open(this.titleModule + ' modificada', '', {
                        verticalPosition: 'top',
                        duration: 2000
                    });

                    this.deselectCurrentPregunta();
                    // this._router.navigate(['/preguntas/filter/' + this.routeParams.filterGroup]);
                })
                .catch((error: any) => {
                    this._matSnackBar.open('Error en actualización', 'Error', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                });
        } else {
            this._matSnackBar.open('Error, es necesario volver a seleccionar el tipo de servicio y grupo de pregunta', 'Error', {
                verticalPosition: 'top',  duration: 3000
            });
        }

        // this._preguntasService.updateTodo(this.preguntaForm.getRawValue());
    }

    /**
     *
     * @param actType
     * @param regId
     */
    deleteItem(regId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea eliminar la ' + this.titleModule + '?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._preguntasService.delete(regId)
                        .then((response: any) => {
                            if (response.code == 200)
                            {
                                this._matSnackBar.open(this.titleModule + ' eliminado', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(response.message, 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }
                            // this._router.navigate(['/preguntas']);
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }

    /**
     *
     */
    savePregunta(): void {
        this._preguntasService.update(this.preguntaForm.value)
            .then(() => {
                this._matSnackBar.open(this.titleModule + ' modificada', '', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                this.deselectCurrentPregunta();
                // this._router.navigate(['/preguntas/filter' + this.routeParams.filterGroup]);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
