import {Deserializable} from './deserializable.model';

export class GasStation implements Deserializable {

    id: number;
    name: string;
    code: string;
    schedule: string;
    direction: string;
    geolocation: {
        type: string,
        coordinates: [0, 0]
    };
    active: number;
    created_at: any;
    updated_at: any;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.name = data.name || '';
        this.code = data.code || '';
        this.schedule = data.schedule || '';
        this.direction = data.direction || '';
        this.geolocation =
            data.geolocation
            ||
            {
                type: 'point',
                coordinates: [0, 0]
            };
        this.active = data.active || 0;
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
