<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-100" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <a routerLink="/"><mat-icon class="secondary-text s-18">home</mat-icon></a>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <a routerLink="/inventario"><span class="secondary-text">INVENTARIO</span></a>
            </div>
            <div class="h1">{{'INVENTARIO.ADD.TITLE' | translate}}</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <!--Subtítulo y descripción de la sección -->
        <!--<div class="h1">Agregar usuario</div>-->
        <!--
        <p class="pt-16 pb-32">
            Da de alta un nuevo usuario
        </p>
        -->

        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

            <!-- REACTIVE FORM EXAMPLE -->

            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" >

                <div class="h2 mb-24">Datos del inventario</div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>STV ID</mat-label>
                        <input matInput name="data_code" formControlName="data_code" maxlength="100" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error >
                            STV ID es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>Tipo de poste</mat-label>
                        <input matInput name="data_type" formControlName="data_type" maxlength="100" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error *ngIf="form.get('data_type').hasError('required')">
                            Tipo de poste es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>IP</mat-label>
                        <input matInput name="data_ip" formControlName="data_ip" maxlength="20" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error *ngIf="form.get('data_ip').hasError('required')">
                            IP es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>Sector</mat-label>
                        <input matInput name="data_sector" formControlName="data_sector" maxlength="20" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error *ngIf="form.get('data_sector').hasError('required')">
                            Sector es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>C2</mat-label>
                        <input matInput name="data_c2" formControlName="data_c2"  maxlength="45" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error *ngIf="form.get('data_c2').hasError('required')">
                            C2 es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                        <mat-label>Dirección</mat-label>
                        <textarea matInput placeholder="Dirección"
                                  name="data_direction"
                                  formControlName="data_direction"
                                  rows="2" maxlength="255">
                        </textarea>
                        <mat-error *ngIf="form.get('data_direction').hasError('required')">
                            Dirección es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>Coordenadas - Latitud</mat-label>
                        <input matInput type="number" name="geolocation_lat" formControlName="geolocation_lat" required>
                        <mat-error *ngIf="form.get('geolocation_lat').hasError('required')">
                            Latitud es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="form.get('geolocation_lat').hasError('pattern')">
                            Latitud tiene un <strong>formato</strong> incorrecto
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                        <mat-label>Coordenadas - Longitud</mat-label>
                        <input matInput type="number" name="geolocation_long" formControlName="geolocation_long" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error *ngIf="form.get('geolocation_long').hasError('required')">
                            Longitud es <strong>requerido</strong>
                        </mat-error>
                        <mat-error *ngIf="form.get('geolocation_long').hasError('pattern')">
                            Longitud tiene un <strong>formato</strong> incorrecto
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end end">
                    <button mat-raised-button type="button" color="warn" class="accent-50 mr-8" routerLink="/inventario">
                        Cancelar
                    </button>
                    <button mat-raised-button type="button" color="accent" [disabled]="!form.valid" (click)="saveInventario()">
                        Guardar
                    </button>
                </div>

            </form>

        </div>

    </div>
</div>