import {Deserializable} from './deserializable.model';
import { User } from './users.model';
import { TeamStaff } from './team-staff.model';

export class Team{

    id: number;
    users_id: number;
    user: User;
    dt_record: any;
    dt_team: any;
    data_code: string;
    data_truck_img: string;
    created_at: any;
    updated_at: any;
    geolocation: {
        type: string,
        coordinates: [0, 0]
    };

    staffs: TeamStaff[];


    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.users_id = data.user_id || 0;
        this.user = data.user || null;
        this.dt_record = data.dt_record || '';
        this.dt_team = data.dt_team || '';
        this.data_code = data.data_code || '';
        this.data_truck_img = data.data_truck_img || '';
        this.geolocation = data.geolocation ||
            {
                type: 'point',
                coordinates: [0, 0]
            };
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;

        this.staffs = data.staffs || [];
    }

    deserialize(input: any): this {
        // Assign input to our object BEFORE deserialize our cars to prevent already deserialized cars from being overwritten.
        Object.assign(this, input);

        // Iterate over all cars for our user and map them to a proper `Car` model
        this.user = new User().deserialize(input.user);
        this.staffs = input.staffs.map1(teamstaff => new TeamStaff().deserialize(teamstaff));

        return this;
    }
}