import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Model
import { Staff } from '../../../models/staff.model';
import { InventarioService } from 'app/main/empleados/empleados.service';

@Component({
    selector: 'app-empleados-add',
    templateUrl: './empleados-add.component.html',
    styleUrls: ['./empleados-add.component.scss'],
    providers: [InventarioService]
})
export class EmpleadosAddComponent implements OnInit, OnDestroy
{
    public form: FormGroup;
    public staff: Staff;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _route
     * @param _router
     * @param _empleadosService
     * @param _location
     * @param _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _empleadosService: InventarioService,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    )
    {
        // Set the private defaults
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {
        this.staff = new Staff();
        // Reactive Form
        this.form = this.createDataForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup
    {
        return this._formBuilder.group({
            id              : [this.staff.id],
            name            : [this.staff.name, [Validators.required]],
            job             : [this.staff.job, [Validators.required]],
            active          : [this.staff.active]
        });

    }

    saveStaff(): void {
        this._empleadosService.create(this.form.value)
            .then(() => {
                    this._matSnackBar.open('Empleado agregado', 'OK', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });

                    this._router.navigate(['/empleados']);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }
}
