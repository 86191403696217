<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: white;">

            <div class="logo">
                <img src="assets/images/logos/TecnoRocket_Logo.svg">
            </div>

            <div class="title">INICIA SESIÓN</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        E-mail es requerido
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Ingrese un e-mail correcto
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password es requerido
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Recordar
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password'">
                        Recuperar password?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid || processNow" (click)="login()">
                    LOGIN
                </button>

            </form>

        </div>

    </div>

</div>
