import {Deserializable} from './deserializable.model';

export class User{

    id: number;
    name: string;
    email: string;
    role: number;
    active: number;
    password: string;
    created_at: any;
    updated_at: any;

    /**
     * Constructor
     *
     * @param product
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.name = data.name || '';
        this.email = data.email || '';
        this.role = data.role || 0;
        this.active = data.active || 0;
        this.password = data.password || '';
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}