<div id="page-checklist" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar sidebar_preguntas" name="preguntas-main-sidebar" position="left" lockedOpen="gt-md">

        <!-- SIDEBAR HEADER -->
        <div fxLayout="column" fxLayoutAlign="center flex-start " class="header accent p-24 pb-4">
            <div fxLayout="row" >
                <a routerLink="/"><mat-icon class="secondary-text s-18">home</mat-icon></a>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <a routerLink="/checklist"><span class="secondary-text">CHECKLIST</span></a>
            </div>
            <div class="h1">{{'CHECKLIST.QUESTIONS.TITLE1' | translate}}</div>

        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div class="nav material2">
            <div class="mat-headline nav-subheader " *ngIf="dataGroups">GRUPOS</div>

            <div class="nav-item" >
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                    <div class="info" fxFlex fxFlexLayout="column">
                        <a class="nav-link" matRipple (click)="setCurrentGroup(-1)"
                           [ngClass]="{'accent':groupSelected == -1}">
                            <span>Todos los grupos</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="nav-item" *ngFor="let _dataGroups of dataGroups">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                    <div class="info" fxFlex fxFlexLayout="column">
                        <a class="nav-link" matRipple (click)="setCurrentGroup(_dataGroups.id)"
                           [ngClass]="{'accent':_dataGroups.id == groupSelected}">
                            <span>{{_dataGroups.name}} [</span>
                            <small>{{_dataGroups.priority}}</small>
                            <span>]</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-24">
            <button mat-raised-button
                    class="mat-accent add-todo-button w-100-p"
                    *ngIf="groupSelected > 0"
                    (click)="asignarPreguntas()"
                    aria-label="Agregar pregunta al grupo">
                Asignar pregunta al grupo
            </button>
        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="column" fxLayoutAlign="center start">

                <div class="h1">{{'CHECKLIST.QUESTIONS.TITLE2' | translate}}: {{currentChecklist.service_type.name}} - ({{currentChecklist.version}})</div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card" >

            <mat-table class="preguntas-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="question.description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.question.description }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="question.type" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Tipo</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.question.type | questionsType}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="question.required">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Requerido</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <mat-icon *ngIf="data.question.required" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!data.question.required" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef >Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Ver información de pregunta" aria-label="Ver información de pregunta"
                                    (click)="verDetallePregunta(data)">
                                <mat-icon class="active-icon " >info</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" title="Quitar pregunta" aria-label="Quitar pregunta" (click)="deleteItem(data.id)">
                                <mat-icon class="active-icon " >delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         matRipple
                        >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
