import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { User } from '../../../models/users.model';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    processNow: boolean;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _LoginService: LoginService,
        private _matSnackBar: MatSnackBar
    )
    {
        this.processNow = false;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.processNow = false;
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }


    login(): void {
        this.processNow = true;
        this._LoginService.checkLogin(this.loginForm.get('email').value, this.loginForm.get('password').value)
            .then((response) => {
                if (response.code === 200) {
                    this._LoginService.setTokenData(response.data.token, new User(response.data.user));
                    this._matSnackBar.open('Usuario permitido', '', {
                        verticalPosition: 'top',
                        duration: 2000
                    });

                    this._router.navigate(['/']);
                } else {
                    this.processNow = false;
                    this._matSnackBar.open('Usuario incorrecto', 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });
                }
            })
            .catch((error: any) => {
                this.processNow = false;
                this._matSnackBar.open('Problemas con acceso', 'Error', {
                    verticalPosition: 'top',
                    duration        : 3000
                });
            });
    }
}
