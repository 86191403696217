import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TeamStaff } from 'app/models/team-staff.model';
import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';
import { Staff } from '../../../../models/staff.model';


@Component({
    selector     : 'empleados-add-dialog',
    templateUrl  : './empleados-add.component.html',
    styleUrls    : ['./empleados-add.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EmpleadosAddDialogComponent
{
    teamId: number;
    action: string;
    dataEmpleados: Staff[];
    dataTeamStaff: TeamStaff;
    dataTeamStaffForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<EmpleadosAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _cuadrillasService: CuadrillasService,
        private _matSnackBar: MatSnackBar,
    )
    {

        // Set the defaults
        this.action = _data.action;
        this.teamId = _data.teamId;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Modificar cuadrilla';
            this.dataTeamStaff = _data.dataTeamStaff;
        }
        else if ( this.action === 'show' )
        {
            this.dialogTitle = 'Información de cuadrilla';
            this.dataTeamStaff = _data.dataTeamStaff;
        }
        else
        {
            this.dialogTitle = 'Nuevo empleado';
            this.dataTeamStaff = new TeamStaff();
        }

        this.dataTeamStaffForm = this.createGroupForm();

        this._cuadrillasService.empleadosGetAll()
            .then((response) => {
                this.dataEmpleados = response.data.staff;


            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en obtención de información', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataTeamStaff.id],
            teams_id: [this.teamId],
            staff_id: [this.dataTeamStaff.staff_id, [Validators.required]],
            comments: [this.dataTeamStaff.comments],
        });
    }




}
