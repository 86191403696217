import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

import { OrdenesService } from 'app/main/ordenes/ordenes.service';
import { Team } from '../../../models/team.model';
import { OrdenesCrearDialogComponent } from '../ordenes-crear-dialog/ordenes-crear-dialog.component';


@Component({
  selector: 'app-ordenes-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class PrincipalComponent implements OnInit, OnDestroy {

    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    tags: any[];
    searchInput: FormControl;
    currentTeamEmpleados: Team;
    currentSeccion: string;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _fuseSidebarService
     * @param _ordenesService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _ordenesService: OrdenesService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        // Obtiene información de la sección a cargar.
        this._ordenesService.onSeccionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.currentSeccion = data;
            });


        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                // this._ordenesService.onSearchTextChanged.next(searchText);
            });

/*
        this._ordenesService.onCurrentCuadrillaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([currentTeam, formType]) => {
                if ( !currentTeam)
                {
                    this.currentTeam = null;
                }
                else
                {
                    this.currentTeam = currentTeam;
                }
            });

        this._ordenesService.onCurrentCuadrillaEmpleadoChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentTeam) => {
                if ( !currentTeam)
                {
                    this.currentTeamEmpleados = null;
                }
                else
                {
                    this.currentTeamEmpleados = currentTeam;
                }
            });
            */

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    crearOrdenes(): void {

        this.dialogRef = this._matDialog.open(OrdenesCrearDialogComponent, {
            panelClass: 'app-ordenes-crear-dialog',
            width: '50vw',
            data: {
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (response == 'success') {

                }
            });

    }

    regresarListado(): void {
        this._ordenesService.setSeccion('listado');
    }

    aceptarValores(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea Aceptar todas las preguntas?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._ordenesService.orderChecklistChangeStatusAllQuestions(this._ordenesService.currentOrdenSelected.id, 20)
                        .then((result: any) => {
                            if (result.code == 200) {
                                this._matSnackBar.open('Preguntas actualizasas', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });

                                this._ordenesService.getOrdersChecklistCurrentOrder();
                            } else {
                                this._matSnackBar.open('Error en actualización', 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }
}
