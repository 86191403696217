
<div id="page-ordenes-lista" >

    <!-- CENTER -->
    <div class="center">

        <div class="content-card">
            <mat-table class="ordenes-table"
                       #table [dataSource]="dataSource"
                       matSort
                       fusePerfectScrollbar
                       [@animateStagger]="{value:'50'}"
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user_operator.name" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Operador</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.user_operator.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="site_points.data_code" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Sitio</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.site_point.data_code}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_assignment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh.Orden</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.dt_assignment | date }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>Estatus</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-md>
                        <p class="text-truncate"></p>
                        <mat-chip-list class="my-chip">
                            <mat-chip class="color-{{data.status | ordersStatus:'color'}}" >{{data.status | ordersStatus}}</mat-chip>
                        </mat-chip-list>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_start_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh.Inicio</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.data_start_date | date }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_start_time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Hr.Inicio</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.data_start_time | date: 'shortTime' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_start">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh.Inicio Real</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{ data.dt_start | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">

                            <button mat-mini-fab color="accent" title="Ver checklist" aria-label="Ver checklist" (click)="verChecklist(data)">
                                <mat-icon class="active-icon " >list_alt</mat-icon>
                            </button>

                            <button mat-mini-fab color="accent" [matMenuTriggerFor]="moreMenu" title="Cambiar estatus" aria-label="Cambiar estatus" (click)="$event.stopPropagation()">
                                <mat-icon class="active-icon " >track_changes</mat-icon>
                            </button>

                            <button mat-mini-fab color="accent" title="Generar Informe" aria-label="Generar Informe" (click)="descargarProtocolo(data)" >
                                <mat-icon class="active-icon " >cloud_download</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">

                                <button mat-menu-item aria-label="Cerrar" (click)="ordenCambioEstatus(data.id, data.status, 40)" *ngIf="data.status == 30">
                                    <ng-container >
                                        <mat-icon class="active-icon " >check_circle</mat-icon>
                                        <span>Cerrar</span>
                                    </ng-container>
                                </button>

                                <button mat-menu-item aria-label="Cancelar" (click)="ordenCambioEstatus(data.id, data.status, 90)" *ngIf="data.status != 90">
                                    <ng-container >
                                        <mat-icon class="active-icon " >cancel</mat-icon>
                                        <span>Cancelar</span>
                                    </ng-container>
                                </button>

                                <button mat-menu-item aria-label="Concluida" (click)="ordenCambioEstatus(data.id, data.status, 30)" *ngIf="data.status == 90">
                                    <ng-container >
                                        <mat-icon class="active-icon " >done</mat-icon>
                                        <span>Concluida</span>
                                    </ng-container>
                                </button>

                            </mat-menu>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Rows definition -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         matRipple
                >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
</div>