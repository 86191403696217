<div id="page-inventario" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    people
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'INVENTARIO.LIST.TITLE' | translate}}</span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper  ml-sm-32">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="end center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Buscar un inventario">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ACTIONS -->
            <div class="toolbar-action-button-row" fxLayout="row" fxLayoutAlign="end center" >
                <button [routerLink]="'/inventario/add'" mat-raised-button color="primary" aria-label="Nuevo inventario" title="Nuevo inventario">Nuevo inventario</button>
            </div>
            <!-- / ACTIONS -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="inventario-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_code" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>STV Id</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.data_code}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de poste</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.data_type}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_ip">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>IP</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.data_ip}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sector</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.data_sector}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="data_c2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>C2</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.data_c2}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="geolocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Coordenadas</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <div>
                            <div class="">Lat: {{data.geolocation.coordinates[0]}}</div>
                            <div class="">Long: {{data.geolocation.coordinates[1]}}</div>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <mat-icon *ngIf="data.active" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!data.active" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Editar" aria-label="Editar" [routerLink]="'/inventario/edit/'+data.id" >
                                <mat-icon class="active-icon " >edit</mat-icon>
                            </button>
                            <button mat-mini-fab color="accent" title="Activar" aria-label="Activar" *ngIf="!data.active" (click)="activate(true, data.id)">
                                <mat-icon class="active-icon " >done</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" title="Inactivar" aria-label="Inactivar" *ngIf="data.active" (click)="activate(false, data.id)">
                                <mat-icon class="active-icon " >highlight_off</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" title="Borrar" aria-label="Borrar" (click)="deleteItem(data.id)">
                                <mat-icon class="active-icon " >delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail" >
                    <mat-cell *matCellDef="let data" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                             [@detailExpand]="data == expandedElement ? 'expanded' : 'collapsed'">
                            <p class="">{{data.data_direction}}</p>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="order"
                         [class.example-expanded-row]="expandedElement === element"
                         (click)="expandedElement = expandedElement === data ? null : data">
                         matRipple
                        >
                </mat-row>

                <mat-row *matRowDef="let row; columns: ['expandedDetail']"
                         class="example-detail-row" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
