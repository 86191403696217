import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { fuseAnimations } from '@fuse/animations';

import { PreguntasService } from 'app/main/preguntas/preguntas.service';
import { ServiceType } from "../../../models/service-type.model";
import { QuestionGroup } from "../../../models/question-group.model";
import { GrupoFormDialogComponent } from '../grupo-form/grupo-form.component';
import {FormGroup} from "@angular/forms";

@Component({
    selector     : 'preguntas-sidebar',
    templateUrl  : './preguntas-sidebar.component.html',
    styleUrls    : ['./preguntas-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class PreguntasSidebarComponent implements OnInit, OnDestroy
{
    folders: any[];
    filters: any[];
    tags: any[];
    selectedTipoServicio: ServiceType;
    selectedGrupo: QuestionGroup;
    dataTiposServicio: ServiceType[];
    dataGruposPreguntas: QuestionGroup[];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _preguntasService
     * @param _router
     */
    constructor(
        private _preguntasService: PreguntasService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        // Carga información del tipo del primer tipo de servicio y los servicios seleccionados
        this._preguntasService.onTiposServiciosChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {

                // this.selectedTipoServicio = (data.length > 0) ? data[0].id.toString() : '';
                this.dataTiposServicio = data;

                // Selecciona el Tipo de servicio elegido
                if (this._preguntasService.currentIdService > 0) {
                    this.selectedTipoServicio = this.dataTiposServicio
                        .find(tipoService => {
                            return Number(tipoService.id) === Number(this._preguntasService.currentIdService);
                        });
                } else {
                    this.selectedTipoServicio = new ServiceType();
                }

                this.getGruposPreguntas();
            });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    getGruposPreguntas(): void {
        if (this.selectedTipoServicio){
            this._preguntasService.getGruposPreguntaByService(this.selectedTipoServicio.id)
                .then((response: any) => {

                    if (response.code == 200)
                    {
                        this.dataGruposPreguntas = response.data.questiongroups;
                    }
                    else
                    {
                        this._matSnackBar.open(response.message, 'Error', {
                            verticalPosition: 'top',
                            duration        : 3000
                        });
                    }


                })
                .catch((error: any) => {
                    this._matSnackBar.open('Error en actualización', 'Error', {
                        verticalPosition: 'top',
                        duration        : 2000
                    });
                });
        }
        /*else {
            this._location.go('preguntas/');
        }*/
    }
    /**
     * New todo
     */
    nuevaPregunta(): void
    {
        this._router.navigate(['/preguntas/all']).then(() => {
            setTimeout(() => {
                this._preguntasService.onNewTodoClicked.next('');
            });
        });
    }

    selectTipo(): void{
        // this._location.go('preguntas/filter/' + this.selectedTipoServicio.id);
        // this.getGruposPreguntas();
        this._preguntasService.setCurrentIdService(this.selectedTipoServicio.id);
        this._preguntasService.setCurrentIdGroup(0);
        this.getGruposPreguntas();
        // this._router.navigate(['/preguntas/filter/' + this.selectedTipoServicio.id]);
    }

    selectGrupo(dataGrupo): void{
        // this._location.go('preguntas/filter/' + this.selectedTipoServicio.id);
        // this.getGruposPreguntas();
        this.selectedGrupo = dataGrupo;
        this._preguntasService.setCurrentIdGroup(dataGrupo.id);
        this._preguntasService.getPreguntas();
        // this._router.navigate(['/preguntas/filter/' + this.selectedTipoServicio.id]);
    }

    grupoNuevo(): void {
        this.dialogRef = this._matDialog.open(GrupoFormDialogComponent, {
            panelClass: 'grupo-form-dialog',
            data      : {
                action: 'new',
                serviceId: this._preguntasService.currentIdService
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._preguntasService.grupoCreate(response.getRawValue())
                    .then(() => {
                        this._matSnackBar.open('Grupo agregado', 'OK', {
                            verticalPosition: 'top',
                            duration        : 2000
                        });

                        this._preguntasService.setCurrentIdGroup(0);
                        this.getGruposPreguntas();
                    })
                    .catch((error: any) => {
                        this._matSnackBar.open('Error en actualización', 'Error', {
                            verticalPosition: 'top',
                            duration        : 2000
                        });
                    });

            });
    }

    grupoEditar(dataGrupo): void
    {
        this.dialogRef = this._matDialog.open(GrupoFormDialogComponent, {
            panelClass: 'grupo-form-dialog',
            data      : {
                dataGrupo: dataGrupo,
                action : 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    /**
                     * Save
                     */
                    case 'save':
                        this._preguntasService.grupoUpdate(formData.getRawValue())
                            .then(() => {
                                this._matSnackBar.open('Grupo modificado', 'OK', {
                                    verticalPosition: 'top',
                                    duration        : 2000
                                });

                                this._preguntasService.setCurrentIdGroup(0);
                                this.getGruposPreguntas();
                            })
                            .catch((error: any) => {
                                this._matSnackBar.open('Error en actualización', 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 2000
                                });
                            });

                        break;
                }
            });
    }

    /**
     * Activate or inactivate
     * @param actType
     * @param regId
     */
    grupoActivate(actType: boolean, regId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea ' + ((actType) ? 'activar' : 'inactivar') + ' al grupo?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._preguntasService.grupoActivate(regId, (actType) ? 1 : 0)
                        .then(() => {
                            this._matSnackBar.open('Grupo ' + ((actType) ? 'activado' : 'inactivado'), '', {
                                verticalPosition: 'top',
                                duration        : 2000
                            });

                            this._preguntasService.setCurrentIdGroup(0);
                            this.getGruposPreguntas();
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 2000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }

    /**
     *
     * @param actType
     * @param regId
     */
    grupoDelete(regId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea eliminar al grupo?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._preguntasService.grupoDelete(regId)
                        .then((response: any) => {
                            if (response.code == 200)
                            {
                                this._matSnackBar.open('Grupo eliminado', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(response.message, 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }
                            this._preguntasService.setCurrentIdGroup(0);
                            this.getGruposPreguntas();
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }
}
