
<div id="page-ordenes-checklist-lista" >

    <!-- CENTER -->
    <div class="center">

        <div class="content-card">
            <mat-table class="ordenes-checklist-table"
                       #table [dataSource]="dataSource"
                       matSort
                       fusePerfectScrollbar
                       [@animateStagger]="{value:'50'}"
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="checklist_question.question.description" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Pregunta</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.checklist_question.question.description}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="value" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Valor</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm [ngSwitch]="true">
                        <div *ngSwitchDefault >
                            <mat-icon *ngIf="data.checklist_question.question.type === 2" class="active-icon primary-500 s-32">image</mat-icon>
                            <mat-icon *ngIf="data.checklist_question.question.type === 4" class="active-icon primary-500 s-32">volume_up</mat-icon>
                        </div>
                        <mat-icon *ngSwitchCase="data.value === 'true'" class="active-icon green-600 s-32">check</mat-icon>
                        <mat-icon *ngSwitchCase="data.value === 'false'" class="active-icon red-500 s-32">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="comments" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Comentarios</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p >{{data.comments}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>Estatus</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-md>
                        <mat-chip-list class="my-chip">
                            <mat-chip class="color-{{data.status | ordersChecklistStatus:'color' }}" >{{data.status | ordersChecklistStatus:'nombre' }}</mat-chip>
                        </mat-chip-list>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">

                            <button mat-mini-fab matcolor="accent" [matMenuTriggerFor]="moreMenu"
                                    title="Cambiar estatus" aria-label="Cambiar estatus" (click)="$event.stopPropagation()" fxHide.xs>
                                <mat-icon class="active-icon " >playlist_add_check</mat-icon>
                            </button>

                            <button mat-mini-fab color="accent" *ngIf="data.checklist_question.question.type === 2" title="Ver Imagen" aria-label="Ver Imagen" (click)="verImagen(data)">
                                <mat-icon class="active-icon " >image</mat-icon>
                            </button>

                            <button mat-mini-fab color="accent" *ngIf="data.checklist_question.question.type === 4" title="Escuchar Audio" aria-label="Escuchar Audio" (click)="verImagen(data)">
                                <mat-icon class="active-icon " >volume_up</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">

                                <button mat-menu-item aria-label="editar" (click)="cambioEstatus(data.id, data.status, 20)" *ngIf="data.status != 20">
                                    <ng-container >
                                        <mat-icon class="active-icon " >done</mat-icon>
                                        <span>Aceptar</span>
                                    </ng-container>
                                </button>

                                <button mat-menu-item aria-label="editar" (click)="cambioEstatus(data.id, data.status, 30)" *ngIf="data.status != 30">
                                    <ng-container >
                                        <mat-icon class="active-icon " >find_replace</mat-icon>
                                        <span>Solicita cambio</span>
                                    </ng-container>
                                </button>

                            </mat-menu>
                            <!--
                            <button mat-mini-fab color="accent" title="Ver historial" aria-label="Ver historial de contenido"
                                    *ngIf="data.locations_count > 0" (click)="verHistorial(data)" >
                                <mat-icon class="active-icon " >map</mat-icon>
                            </button>
                            -->
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Rows definition -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         (click)="verMasInfo(data)"
                         matRipple
                >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
</div>