import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GasStation } from 'app/models/gas-station.model';

@Component({
    selector     : 'gasolineras-mapa-dialog',
    templateUrl  : './gasolineras-mapa.component.html',
    styleUrls    : ['./gasolineras-mapa.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GasolinerasMapaDialogComponent
{
    gasId: number;
    action: string;
    dataGasStation: GasStation;
    dataGasStationForm: FormGroup;
    dialogTitle: string;
    lat: number;
    lng: number;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<GasolinerasMapaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,

    )
    {
        // Set the defaults
        this.action = _data.action;
        this.gasId = _data.gruaId;
        this.dialogTitle = 'Ubicación de Gasolinera';
        this.dataGasStation = _data.dataCheck;
        this.lat = this.dataGasStation.geolocation.coordinates[0];
        this.lng = this.dataGasStation.geolocation.coordinates[1];


        this.dataGasStationForm = this.createGroupForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataGasStation.id],
            name: [{
                value: this.dataGasStation.name,
                disabled: true
            }],
            geolocation: [this.dataGasStation.geolocation],
            geolocation_lat: [{
                value: this.dataGasStation.geolocation.coordinates[0],
                disabled: true
            }],
            geolocation_long: [{
                value: this.dataGasStation.geolocation.coordinates[0],
                disabled: true
            }],
        });
    }

}
