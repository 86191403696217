import {Deserializable} from './deserializable.model';
import {Orders} from './orders.model';
import {ChecklistQuestions} from './checklist-questions.model';

export class OrdersChecklist implements Deserializable
{
    id: number;
    orders_id: number;
    checklist_questions_id: number;
    value: string;
    comments: string;
    geolocation: {
        type: string,
        coordinates: [0, 0]
    };
    status: number;
    comments_reject: string;
    dt_record: any;
    created_at: any;
    updated_at: any;
    user_id: number;
    order: Orders;
    checklist_question: ChecklistQuestions;


    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        {
            this.id = data.id || 0;
            this.orders_id = data.orders_id|| 0;
            this.checklist_questions_id = data.checklist_questions_id|| 0;
            this.value = data.value || '';
            this.comments= data.comments|| '';
            this.geolocation = data.geolocation || {
                    type: 'point',
                    coordinates: [0, 0]
                };
            this.status = data.status || 0;
            this.comments_reject= data.comments_reject|| '';
            this.dt_record = data.dt_record || '';
            this.created_at= data.created_at || '';
            this.updated_at= data.updated_at || '';
            this.user_id= data.user_id || 0;
            this.order= data.order || null;
            this.checklist_question= data.checklist_question || null;
        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        this.order = new Orders().deserialize(input.order);
        this.checklist_question = new ChecklistQuestions().deserialize(input.checklist_question);
        return this;
    }

}
