import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';

import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private _loginService: LoginService,
        private _router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 401) {
                        this._loginService.logout();
                        this._router.navigate(['/auth/login']);
                    }
                    return throwError(error);
                    /*
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        // errorMessage = `Codigo: ${error.status}\nMessage: ${error.error.message}`;
                        errorMessage = error;
                    }
                    // window.alert(errorMessage);
                    return throwError(errorMessage);*/
                })
            );
    }
}

