<!-- SIDEBAR HEADER -->
<div fxLayout="column" fxLayoutAlign="space-between start"
     class="header accent p-24 pb-4">
    <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            live_help
        </mat-icon>
        <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">PREGUNTAS  </span>
    </div>

    <div class="cssTipoService" fxLayout="column" [@animate]="{value:'*',params:{delay:'300ms'}}">
        <div class="title">Tipo de servicio </div>
        <mat-form-field floatLabel="never">
            <mat-select class="account-selection" placeholder="Seleccione un tipo"
                        [(ngModel)]="selectedTipoServicio" (selectionChange)="selectTipo()" >
                <mat-option *ngFor="let tipoServicio of dataTiposServicio" [value]="tipoServicio">
                    {{tipoServicio.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<!-- / SIDEBAR HEADER -->

<!-- SIDEBAR CONTENT -->
<div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{y:'50px'}}">

    <div class="nav material2">

        <!--
        <div class="nav-item">
            <a class="nav-link" matRipple [routerLink]="'/preguntas/all'"
               [routerLinkActive]="['active', 'accent']">
                <mat-icon class="nav-link-icon">view_headline</mat-icon>
                <span>All</span>
            </a>
        </div>
        -->

        <!--
        <div class="nav-subheader">FILTERS</div>

        <div class="nav-item" *ngFor="let filter of filters">
            <a class="nav-link" matRipple [routerLink]="'/preguntas/filter/' + filter.handle"
               [routerLinkActive]="['active', 'accent']">
                <mat-icon class="nav-link-icon" *ngIf="filter.icon">{{filter.icon}}</mat-icon>
                <span>{{filter.title}}</span>
            </a>
        </div>
        -->

        <div class="mat-headline nav-subheader " *ngIf="dataGruposPreguntas">GRUPOS</div>

        <div class="nav-item" *ngFor="let _datGrupoPregunta of dataGruposPreguntas">
            <!-- <a class="nav-link" matRipple [routerLink]="'/preguntas/filter/' + selectedTipoServicio.id + '/' +_datGrupoPregunta.id" -->
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                <div class="info" fxFlex fxFlexLayout="column">


                    <a class="nav-link" matRipple (click)="selectGrupo(_datGrupoPregunta)"
                       [ngClass]="{'accent':_datGrupoPregunta == selectedGrupo}">
                        <mat-icon *ngIf="_datGrupoPregunta.active" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!_datGrupoPregunta.active" class="active-icon red-500 s-16">close</mat-icon>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>{{_datGrupoPregunta.name}} [ <small>{{_datGrupoPregunta.priority}}</small> ]</span>
                    </a>
                </div>
                <div class="actions" fxLayout="row" fxLayoutAlign="start center">

                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" (click)="$event.stopPropagation()"
                            fxHide.xs>
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>

                    <mat-menu #moreMenu="matMenu">

                        <button mat-menu-item aria-label="editar" (click)="grupoEditar(_datGrupoPregunta)">
                            <ng-container >
                                <mat-icon class="active-icon " >edit</mat-icon>
                                <span>Editar</span>
                            </ng-container>
                        </button>

                        <button mat-menu-item aria-label="activar" *ngIf="!_datGrupoPregunta.active" (click)="grupoActivate(true, _datGrupoPregunta.id)">
                            <ng-container >
                                <mat-icon class="active-icon " >done</mat-icon>
                                <span>Activar</span>
                            </ng-container>
                        </button>

                        <button mat-menu-item aria-label="inactivar" *ngIf="_datGrupoPregunta.active" (click)="grupoActivate(false, _datGrupoPregunta.id)">
                            <ng-container >
                                <mat-icon class="active-icon " >highlight_off</mat-icon>
                                <span>Inactivar</span>
                            </ng-container>
                        </button>

                        <button mat-menu-item aria-label="borrar" (click)="grupoDelete(_datGrupoPregunta.id)">
                            <ng-container >
                                <mat-icon class="active-icon " >delete</mat-icon>
                                <span>Eliminar</span>
                            </ng-container>
                        </button>

                    </mat-menu>

                </div>
            </div>

        </div>


    </div>

    <div class="p-24">
        <button mat-raised-button
                class="mat-accent add-todo-button w-100-p"
                *ngIf="selectedTipoServicio.id > 0"
                (click)="grupoNuevo()"
                aria-label="Nuevo grupo">
            Nuevo grupo
        </button>
    </div>

</div>
<!-- / SIDEBAR CONTENT -->
