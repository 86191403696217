<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="dataPreguntaForm" [formGroup]="dataPreguntaForm" >

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="outline" fxFlex >
                    <mat-label>Descripción</mat-label>
                    <input matInput name="description" formControlName="description" maxlength="255">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="outline" fxFlex >
                    <mat-label>Tipo de campo</mat-label>
                    <mat-select name="type" formControlName="type" required>
                        <mat-option [value]="1">
                            Checkbox
                        </mat-option>
                        <mat-option [value]="2">
                            Foto
                        </mat-option>
                        <mat-option [value]="4">
                            Audio
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div appearance="outline" fxFlex>
                    <mat-checkbox name="required" formControlName="required" >Información, ¿Es requerida?</mat-checkbox>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div appearance="outline" fxFlex>
                    <mat-checkbox class="example-margin" name="comments_visible" formControlName="comments_visible" >Comentario, ¿Es visible?</mat-checkbox>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div appearance="outline" fxFlex class="mb-8">
                    <mat-checkbox class="example-margin" name="comments_required" formControlName="comments_required" >Comentario, ¿Es requerido</mat-checkbox>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Etiqueta para comentario</mat-label>
                    <input matInput name="comments_label" formControlName="comments_label" maxlength="255" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex >
                    <mat-label>Tipo de comentario</mat-label>
                    <mat-select name="comments_type" formControlName="comments_type" required>
                        <mat-option [value]="1">
                            Abierto
                        </mat-option>
                        <mat-option [value]="2">
                            Código de barras
                        </mat-option>
                        <mat-option [value]="4">
                            Código QR
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>
