import {Deserializable} from './deserializable.model';
import {TruckLocation} from './truck-location.model';

export class Truck implements Deserializable {

    id: number;
    name: string;
    code: string;
    phone_number: string;
    active: number;
    created_at: any;
    updated_at: any;
    locations_count: number;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.name = data.name || '';
        this.code = data.code || '';
        this.phone_number= data.phone_number || '';
        this.active = data.active || 0;
        this.locations_count = data.locations_count || 0;
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}
