export const locale = {
    lang: 'es',
    data: {
        GRUAS: {
            EDIT: {
                TITLE: 'MODIFICAR GRÚA'
            }
        }
    }
};
