import {Deserializable} from './deserializable.model';
import {Truck} from './truck.model';

export class TruckLocation implements Deserializable {

    id: number;
    trucks_id: number;
    truck: Truck;
    dt_request: any;
    dt_response: any;
    data_device: string;
    geolocation: {
        type: string,
        coordinates: [0, 0]
    };

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.trucks_id = data.trucks_id || '';
        this.truck = data.truck || null;
        this.dt_request = data.dt_request || null;
        this.dt_response = data.dt_response || null;
        this.data_device = data.data_device || '';
        this.geolocation = data.geolocation ||
            {
                type: 'point',
                coordinates: [0, 0]
            };
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        // this.truck = new Truck().deserialize(input.truck);

        return this;
    }
}
