import {Deserializable} from './deserializable.model';
import { ServiceType } from './service-type.model';
import {Question} from './question.model';

export class Checklist implements Deserializable{

    id: number;
    service_type_id: number;
    service_type: ServiceType;
    version: string;
    dt_record: any;
    dt_start: any;
    created_at: any;
    updated_at: any;

    questions_count: number;
    


    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.service_type_id = data.service_type_id || 0;
        this.service_type = data.service_type || null;
        this.version = data.version || '';
        this.dt_record = data.dt_record || '';
        this.dt_start = data.dt_start || '';
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;

        this.questions_count = data.questions_count || 0;

    }

    deserialize(input: any): this {
        Object.assign(this, input);
        
        this.service_type = new ServiceType().deserialize(input.user);

        return this;
    }

}