import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';
import { Team } from '../../../models/team.model';

@Component({
    selector     : 'app-cuadrillas-principal',
    templateUrl  : './cuadrillas-principal.component.html',
    styleUrls    : ['./cuadrillas-principal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CuadrillasPrincipalComponent implements OnInit, OnDestroy
{
    tags: any[];
    searchInput: FormControl;
    currentTeam: Team;
    currentTeamEmpleados: Team;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _fuseSidebarService
     * @param _cuadrillasService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _cuadrillasService: CuadrillasService
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._cuadrillasService.onSearchTextChanged.next(searchText);
            });


        this._cuadrillasService.onCurrentCuadrillaChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([currentTeam, formType]) => {
                if ( !currentTeam)
                {
                    this.currentTeam = null;
                }
                else
                {
                    this.currentTeam = currentTeam;
                }
            });

        this._cuadrillasService.onCurrentCuadrillaEmpleadoChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentTeam) => {
                if ( !currentTeam)
                {
                    this.currentTeamEmpleados = null;
                }
                else
                {
                    this.currentTeamEmpleados = currentTeam;
                }
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

}
