// Angular y tema
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

// Módulo
import { OrdenesService } from 'app/main/ordenes/ordenes.service';
import { OrdersChecklist } from '../../../models/orders-checklist.model';
import { PreguntaImagenDialogComponent } from './pregunta-imagen-dialog/pregunta-imagen-dialog.component';

@Component({
  selector: 'app-ordenes-checklist-listado',
  templateUrl: './ordenes-checklist-listado.component.html',
  styleUrls: ['./ordenes-checklist-listado.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class OrdenesChecklistListadoComponent implements OnInit {

    dialogRef: any;

    dataSource: FilesDataSource | null;
    displayedColumns = ['id', 'checklist_question.question.description', 'value', 'comments', 'status', 'acciones'];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    private titleModule: string;

    /**
     * Constructor
     *
     * @param _ordenesService
     * @param _fuseTranslationLoaderService
     * @param _matSnackBar
     * @param _router
     * @param _matDialog
     */
    constructor(
        private _ordenesService: OrdenesService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
    )
    {
        // Set the private defaults
        this.titleModule = 'Detalle de Orden';
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._ordenesService, this.paginator, this.sort);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Get image for a Order-Checklist
     * @param data
     */
    verImagen(data: OrdersChecklist ): void {
        this.dialogRef = this._matDialog.open(PreguntaImagenDialogComponent, {
            panelClass: 'app-pregunta-imagen-dialog',
            // width: '50vw',
            data: {
                action: (data.checklist_question.question.type === 2 ) ? 'imagen' : 'audio',
                orderChecklistId: data.id,
                dataOrderChecklist: data
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (response == 'success') {

                }
            });
    }

    cambioEstatus(orderChecklistId: number, statusActual: number, statusNuevo: number): void {
        this._ordenesService.orderChecklistChangeStatus(orderChecklistId, statusActual, statusNuevo)
            .then(() => {
                this._matSnackBar.open('Pregunta actualizada', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this._ordenesService.getOrdersChecklistCurrentOrder();
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en actualización de preguntas', 'Error', {
                    verticalPosition: 'top',
                    duration: 3000
                });
            });
    }

    verMasInfo(dataCuadrilla): void {
        /**
         this.dialogRef = this._matDialog.open(CuadrillasDetalleDialogComponent, {
            panelClass: 'cuadrillas-detalle-dialog',
            data      : {
                dataCuadrilla: dataCuadrilla,
                action : 'show'
            }
        });

         this.dialogRef.afterClosed()
         .subscribe(response => {});
         */
    }
}

export class FilesDataSource extends DataSource<any>
{
    // Private
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param _ordenesService
     * @param _matPaginator
     * @param _matSort
     */
    constructor(
        private _ordenesService: OrdenesService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    )
    {
        super();

        this.filteredData = [];
        // this.filteredData = this._cuadrillasService.dataCuadrillasAll;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this._ordenesService.onOrdenesChecklistChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {
                if (this._ordenesService.dataOrdenesChecklistAll) {
                    let data = this._ordenesService.dataOrdenesChecklistAll.slice();

                    data = this.filterData(data);

                    this.filteredData = [...data];

                    data = this.sortData(data);

                    // Grab the page's slice of data.
                    const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return data.splice(startIndex, this._matPaginator.pageSize);
                }
            })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'checklist_question.question.description':
                    [propertyA, propertyB] = [a.user_operator.name, b.user_operator.name];
                    break;
                case 'value':
                    [propertyA, propertyB] = [a.site_points.data_code, b.site_points.data_code];
                    break;
                case 'comments':
                    [propertyA, propertyB] = [a.dt_assignment, b.dt_assignment];
                    break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}