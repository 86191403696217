<div id="page-gasolineras" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">local_gas_station</mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'GASOLINERAS.LIST.TITLE' | translate}}</span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper  ml-sm-32">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="end center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Buscar una gasolinera">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ACTIONS -->
            <div class="toolbar-action-button-row" fxLayout="row" fxLayoutAlign="end center" >
                <button [routerLink]="'/gasolineras/add'" mat-raised-button color="primary" aria-label="Nueva Gasolinera" title="Nueva Gasolinera">Nueva Gasolinera</button>
            </div>
            <!-- / ACTIONS -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="gasolineras-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Código</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.code}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="schedule">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Horarios</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.schedule}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="geolocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Coordenadas</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <div>
                            <div class="">Lat: {{data.geolocation.coordinates[0]}}</div>
                            <div class="">Long: {{data.geolocation.coordinates[1]}}</div>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Editar" aria-label="Editar" [routerLink]="'/gasolineras/edit/'+data.id" >
                                <mat-icon class="active-icon " >edit</mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" title="Borrar" aria-label="Borrar" (click)="deleteItem(data.id)">
                                <mat-icon class="active-icon " >delete</mat-icon>
                            </button>
                            <button mat-mini-fab color="accent" title="Ver Mapa" aria-label="Ver Mapa" (click)="verMasInfo(data)">
                                <mat-icon class="active-icon " >pin_drop</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail" >
                    <mat-cell *matCellDef="let data" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                             [@detailExpand]="data == expandedElement ? 'expanded' : 'collapsed'">
                            <p class="">{{data.direction}}</p>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="order"
                         [class.example-expanded-row]="expandedElement === element"
                         (click)="expandedElement = expandedElement === data ? null : data">
                         matRipple
                        >
                </mat-row>

                <mat-row *matRowDef="let row; columns: ['expandedDetail']"
                         class="example-detail-row"
                         matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
