import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { GLOBAL } from '../../global/global';
import { LoginService } from 'app/main/login/login.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ',
    })
};


@Injectable({
    providedIn: 'root'
})
export class InventarioService implements Resolve<any>
{
    public urlAPI: string;
    dataAll: any[];
    onOrdersChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService
    )
    {
        // Set the defaults
        this.onOrdersChanged = new BehaviorSubject({});
        this.urlAPI = GLOBAL.url;
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get dataAll
     *
     * @returns {Promise<any>}
     */
    getData(): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'staff', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.dataAll = response.data.staff;
                        this.onOrdersChanged.next(this.dataAll);
                        resolve(response);
                 }, reject);
        });
    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    create(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                staff: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'staff', myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get data of item
     * @param token
     * @param car
     */
    getOne(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'staff/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Update data
     * @param token
     * @param car
     */
    update(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                staff: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'staff/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    delete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'staff/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    activate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        let httpOptions2 = httpOptions;
        httpOptions2.headers = httpOptions2.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'staff/activate/' + id + '/' + type, myParam, httpOptions2).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
}
