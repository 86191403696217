import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { GLOBAL } from '../../global/global';
import { User } from '../../models/users.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    })
};


@Injectable({
    providedIn: 'root'
})
export class LoginService
{
    public urlAPI: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.urlAPI = GLOBAL.url;
    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    checkLogin(paramUser: string, paramPwd: string): Promise<any>{
        const myData = {
            data: {
                user: paramUser,
                password: paramPwd
            }
        };
        const myParam = JSON.stringify(myData);

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'session/login', myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Save token an user data in LocalStorage
     * @param token
     * @param user
     */
    setTokenData(token: string, user: User): void {
        localStorage.setItem('currentUserId', user.id.toString());
        localStorage.setItem('currentUserName', user.name);
        localStorage.setItem('currentUserMail', user.email);
        localStorage.setItem('token', token);
    }

    /**
     * Get token from Local Storage
     * @returns {string|null}
     */
    getToken(): string {
        return localStorage.getItem('token');
    }

    /**
     * Get User from Local Storage
     * @returns {string|null}
     */
    getUser(): User {
        let userData: User;
        userData = new User();
        userData.id = Number(localStorage.getItem('currentUserId'));
        userData.name = localStorage.getItem('currentUserName');
        userData.email = localStorage.getItem('currentUserMail');
        return userData;
    }

    /**
     * Determine if token exist then isLogged else is not Logged
     * @returns {boolean}
     */
    isLoggedIn(): boolean {
        const usertoken = this.getToken();
        if (usertoken != null) {
            return true;
        }
        return false;
    }


    /**
     * Clear token
     */
    logout(): void {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUserName');
        localStorage.removeItem('currentUserMail');
    }


}
