import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usuariosRole'
})
export class UsuariosRolePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
        case 1:
            return 'Administrador';
            break;
        case 2:
            return 'APP';
            break;
        case 3:
            return 'Administrador y APP';
            break;
        default:
            return 'ERR';
            break;
    }

  }
}
