<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="dataOrderChecListForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nombre</mat-label>
                    <input name="name" formControlName="name" matInput maxlength="200" readonly >
                </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="dataOrderChecklist.checklist_question.question.type === 2">
                <div class="title mat-subheading-3 mt-0">Imágen </div>
                <img [src]="imageToShow" class="elementos-img mb-20">
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="dataOrderChecklist.checklist_question.question.type === 4">
                <div class="title mat-subheading-3 mt-0">Audio </div>
                <audio controls #audioOption class="elementos-img mb-20">
                    <source src="" type="audio/mpeg">
                    <p>Tu navegador no soporta audio en HTML5</p>
                </audio>

            </div>

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Coordenadas - Latitud</mat-label>
                    <input matInput type="number" name="geolocation_lat" formControlName="geolocation_lat" readonly >
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                    <mat-label>Coordenadas - Longitud</mat-label>
                    <input matInput type="number" name="geolocation_long" formControlName="geolocation_long" readonly >
                </mat-form-field>
            </div>
            <agm-map [latitude]="lat" [longitude]="lng">
                <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    </div>

</div>
