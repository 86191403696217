import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Question } from 'app/models/question.model';
import { PreguntasService } from 'app/main/preguntas/preguntas.service';

import { QuestionsTypePipe } from '../../../../models/pipes/questions-type.pipe';

@Component({
    selector     : 'preguntas-list-item',
    templateUrl  : './preguntas-list-item.component.html',
    styleUrls    : ['./preguntas-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PreguntasListItemComponent implements OnInit, OnDestroy
{
    tags: any[];

    @Input()
    pregunta: Question;

    @HostBinding('class.selected')
    selected: boolean;

    @HostBinding('class.move-disabled')
    moveDisabled: boolean;

    titleModule: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {TodoService} _todoService
     * @param {ActivatedRoute} _activatedRoute
     */
    constructor(
        private _preguntasService: PreguntasService,
        private _activatedRoute: ActivatedRoute,
    )
    {
        this.titleModule = "Pregunta"
        // Disable move if path is not /all
        if ( _activatedRoute.snapshot.url[0].path !== 'all' )
        {
            this.moveDisabled = true;
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the initial values
        this.pregunta = new Question(this.pregunta);

        // Subscribe to update on selected todo change
        this._preguntasService.onSelectedTodosChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedPreguntas => {
                this.selected = false;

                if ( selectedPreguntas.length > 0 )
                {
                    for ( const preg of selectedPreguntas )
                    {
                        if ( preg.id === this.pregunta.id )
                        {
                            this.selected = true;
                            break;
                        }
                    }
                }
            });

        // Subscribe to update on tag change
        this._preguntasService.onTagsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(tags => {
                this.tags = tags;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


}
