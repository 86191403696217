// Angular y tema
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { takeUntil } from 'rxjs/internal/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

// Translate
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

// Módulo
import { ChecklistService } from '../checklist.service';
import { Checklist } from '../../../models/checklist.model';
import { QuestionGroup } from '../../../models/question-group.model';
import { ChecklistQuestions } from '../../../models/checklist-questions.model';
import { DetallePreguntaDialogComponent } from './detalle-pregunta/detalle-pregunta.component'
import { AddPreguntaDialogComponent } from './add-preguntas/add-pregunta.component'
import { QuestionsTypePipe } from '../../../models/pipes/questions-type.pipe';

@Component({
    selector: 'app-checklist-preguntas',
    templateUrl: './checklist-preguntas.component.html',
    styleUrls: ['./checklist-preguntas.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],

})
export class ChecklistPreguntasComponent implements OnInit, OnDestroy {

    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    dataSource: FilesDataSource | null; // ChecklistQuestions[];
    displayedColumns = ['id', 'question.description', 'question.type', 'question.required', 'acciones'];

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    // Private
    private _unsubscribeAll: Subject<any>;

    private titleModule: string;

    paramChecklistId: number;
    currentChecklist: Checklist;
    dateIni: FormControl;
    dateFin: FormControl;
    startDate: Date;

    dataGroups: QuestionGroup[];
    groupSelected: number;

    /**
     * Constructor
     *
     * @param _checklistService
     * @param _fuseTranslationLoaderService
     * @param _matSnackBar
     * @param _router
     * @param _matDialog
     */
    constructor(
        private _checklistService: ChecklistService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute,
        private _matDialog: MatDialog
    )
    {
        // Set the private defaults

        this.titleModule = 'Pregunta';
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish);
        this.currentChecklist = _checklistService.currentCheck;
        this.groupSelected = -1;

        if (!this.currentChecklist){
            this._router.navigate(['/checklist']);
        }

        this._route.params.subscribe((params: Params) => {
            this.paramChecklistId = params.id;
        });

        this._checklistService.groupQuestionGetByServiceType(this.currentChecklist.service_type_id)
            .then((response) => {
                this.dataGroups = response.data.questiongroups;
                this.setCurrentGroup(-1);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en obtención de información', 'Error', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._checklistService, this.paginator, this.sort);

        // Subscribe to update todos on changes
        /*
        this._checklistService.onQuestionChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(preguntas => {
                this.dataSource = preguntas;
            });
        */

        /*
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
            */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setCurrentGroup(groupId: number): void{
        this.groupSelected = groupId;
        this._checklistService.questionGetData(this.paramChecklistId, this.groupSelected);
    }

    verDetallePregunta(dataCheckListPregunta): void {
        if (dataCheckListPregunta.question === null){
            this._matSnackBar.open('No existen pregunta', '', {
                verticalPosition: 'top',
                duration        : 2000
            });
        } else {
            this.dialogRef = this._matDialog.open(DetallePreguntaDialogComponent, {
                panelClass: 'detalle-pregunta-dialog',
                data: {
                    preguntaId: dataCheckListPregunta.question.id,
                    dataPregunta: dataCheckListPregunta.question,
                    action: 'show'
                }
            });
            this.dialogRef.afterClosed()
                .subscribe(response => {});
        }
    }

    asignarPreguntas(): void {
        if (this.groupSelected <= 0 ){
            this._matSnackBar.open('Debe seleccionar un grupo', 'Error', {
                verticalPosition: 'top',
                duration        : 3000
            });
        } else {
            this.dialogRef = this._matDialog.open(AddPreguntaDialogComponent, {
                panelClass: 'add-pregunta-dialog',
                data: {
                    groupId: this.groupSelected,
                    checklistId: this.paramChecklistId,
                    action: 'new'
                }
            });
            this.dialogRef.afterClosed()
                .subscribe(response => {
                    if (response == 'success') {
                        if (this.paramChecklistId > 0 && this.groupSelected > 0) {
                            this._checklistService.questionGetData(this.paramChecklistId, this.groupSelected);
                        }
                    }
                });
        }
    }

    /**
     *
     * @param actType
     * @param regId
     */
    deleteItem(regId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Desea quitar la  ' + this.titleModule + ' del checklist?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._checklistService.questionDelete(regId)
                        .then((response: any) => {
                            if (response.code == 200)
                            {
                                this._matSnackBar.open(this.titleModule + ' eliminado', '', {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(response.message, 'Error', {
                                    verticalPosition: 'top',
                                    duration        : 3000
                                });
                            }
                            this._checklistService.questionGetData(this.paramChecklistId, this.groupSelected);
                        })
                        .catch((error: any) => {
                            this._matSnackBar.open('Error en actualización', 'Error', {
                                verticalPosition: 'top',
                                duration        : 3000
                            });
                        });
                }
                this.confirmDialogRef = null;
            });
    }


}

export class FilesDataSource extends DataSource<any>
{
    // Private
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param _checklistService
     * @param _matPaginator
     * @param _matSort
     */
    constructor(
        private _checklistService: ChecklistService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    )
    {
        super();

        this.filteredData = this._checklistService.dataAllQuestions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this._checklistService.onQuestionChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(map(() => {

                let data = this._checklistService.dataAllQuestions.slice();

                data = this.filterData(data);

                this.filteredData = [...data];

                data = this.sortData(data);

                // Grab the page's slice of data.
                const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                return data.splice(startIndex, this._matPaginator.pageSize);
            })
        );

    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'question.description':
                    [propertyA, propertyB] = [a.question.description, b.question.description];
                    break;
                case 'question.type':
                    [propertyA, propertyB] = [a.question.type, b.question.type];
                    break;
                case 'question.required':
                    [propertyA, propertyB] = [a.question.required, b.question.required];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
