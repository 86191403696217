export class DataUserFakeDb
{

    public static usuarios = [
        {
            'id'            : 1,
            'name'          : 'Usuario 1',
            'email'         : 'mail1@mail.com',
            'role'          : 'admin',
            'active'        : 1

        },
        {
            'id'            : 2,
            'name'          : 'Usuario 2',
            'email'         : 'mail2@mail.com',
            'role'          : 'admin',
            'active'        : 1

        },
        {
            'id'            : 3,
            'name'          : 'Usuario 3',
            'email'         : 'mail3@mail.com',
            'role'          : 'admin',
            'active'        : 0

        },
        {
            'id'            : 4,
            'name'          : 'Usuario 4',
            'email'         : 'mail4@mail.com',
            'role'          : 'admin',
            'active'        : 1

        },
        {
            'id'            : 5,
            'name'          : 'Usuario 5',
            'email'         : 'mail5@mail.com',
            'role'          : 'admin',
            'active'        : 1

        },
        {
            'id'            : 6,
            'name'          : 'Usuario 6',
            'email'         : 'mail6@mail.com',
            'role'          : 'admin',
            'active'        : 0

        },
        {
            'id'            : 7,
            'name'          : 'Usuario 7',
            'email'         : 'mail7@mail.com',
            'role'          : 'admin',
            'active'        : 1

        },
        {
            'id'            : 8,
            'name'          : 'Usuario 8',
            'email'         : 'mail8@mail.com',
            'role'          : 'admin',
            'active'        : 1

        }
    ];
}
