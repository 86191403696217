import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            /*{
                id       : 'sample',
                title    : 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },*/
            {
                id       : 'usuarios',
                title    : 'Usuarios',
                translate: 'NAV.USUARIOS.LIST.TITLE',
                type     : 'item',
                icon     : 'people',
                url      : '/usuarios',
            },
            {
                id       : 'empleados',
                title    : 'Empleados',
                translate: 'NAV.EMPLEADOS.LIST.TITLE',
                type     : 'item',
                icon     : 'nature_people',
                url      : '/empleados',
            },
            {
                id       : 'inventario',
                title    : 'Inventario',
                translate: 'NAV.INVENTARIO.LIST.TITLE',
                type     : 'item',
                icon     : 'control_camera',
                url      : '/inventario',
            },
            {
                id       : 'tiposservicio',
                title    : 'Tipos de servicio',
                translate: 'NAV.TIPOSSERVICIO.LIST.TITLE',
                type     : 'item',
                icon     : 'local_library',
                url      : '/tiposservicio',
            },
            {
                id       : 'preguntas',
                title    : 'Banco de Preguntas',
                translate: 'NAV.PREGUNTAS.LIST.TITLE',
                type     : 'item',
                icon     : 'live_help',
                url      : '/preguntas',
            },
            {
                id       : 'cuadrillas',
                title    : 'Cuadrillas',
                translate: 'NAV.CUADRILLAS.LIST.TITLE',
                type     : 'item',
                icon     : 'supervisor_account',
                url      : '/cuadrillas',
            },
            {
                id       : 'gasolineras',
                title    : 'Gasolineras',
                translate: 'NAV.GASOLINERAS.LIST.TITLE',
                type     : 'item',
                icon     : 'local_gas_station',
                url      : '/gasolineras',
            },
            {
                id       : 'gruas',
                title    : 'Gruas',
                translate: 'NAV.GRUAS.LIST.TITLE',
                type     : 'item',
                icon     : 'local_shipping',
                url      : '/gruas',
            },
            {
                id       : 'checklist',
                title    : 'Checklist',
                translate: 'NAV.CHECKLIST.LIST.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/checklist',
            },
            {
                id       : 'ordenes',
                title    : 'Ordenes',
                translate: 'NAV.ORDENES.LIST.TITLE',
                type     : 'item',
                icon     : 'format_list_bulleted',
                url      : '/ordenes',
            },
            /*
            {
                id       : 'login',
                title    : 'Login',
                translate: 'NAV.LOGIN.LIST.TITLE',
                type     : 'item',
                icon     : 'vpn_key',
                url      : '/auth/login',
            }*/
        ]
    }
];
