import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Question } from 'app/models/question.model';
import { GLOBAL } from "../../global/global";
import { LoginService } from 'app/main/login/login.service';
import {Team} from "../../models/team.model";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ',
        }
    )};

@Injectable()
export class CuadrillasService implements Resolve<any>
{


    onOperadoresChanged: BehaviorSubject<any>;      // Behavior para cuando cambia el listado de operadores
    onCuadrillasChanged: BehaviorSubject<any>;      // Behavior para cuando se cambia listado de cuadrillas
    onCurrentCuadrillaChanged: BehaviorSubject<any>;
    onCurrentCuadrillaEmpleadoChanged: BehaviorSubject<any>;

    dataCuadrillasAll: any[];     // Listado de todas las cuadrillas
    currentCuadrilla: Team;
    currentIdOperador: number;

    showCuadrilla: boolean;
    showEmpleados: boolean;

    filter: {
        users_id: number,
        date_ini: string,
        date_fin: string
    };







    selectedTodos: Question[];
    searchText: string;
    filters: any[];
    routeParams: any;



    onSelectedTodosChanged: BehaviorSubject<any>;

    onFiltersChanged: BehaviorSubject<any>;
    onTagsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    onNewTodoClicked: Subject<any>;


    private urlAPI: string;
    private moduleAPI: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Location} _location
     */
    constructor(
        private _httpClient: HttpClient,
        private _location: Location,
        private _loginService: LoginService
    ) {
        // Set the defaults
        this.selectedTodos = [];
        this.searchText = '';
        this.onSelectedTodosChanged = new BehaviorSubject([]);
        this.onCuadrillasChanged = new BehaviorSubject([]);
        this.onCurrentCuadrillaChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onTagsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
        this.onNewTodoClicked = new Subject();
        this.onOperadoresChanged = new BehaviorSubject('');
        this.onCurrentCuadrillaEmpleadoChanged = new BehaviorSubject(null);
        this.urlAPI = GLOBAL.url;
        this.moduleAPI = 'questions';

        // OBtiene tipos de servicio.
        // this.getOperadores();
        this.filter = {
            users_id: 0,
            date_ini: '',
            date_fin: ''
        };
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        // Devuelve información
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getOperadores(),
                // this.getCuadrillas(),

            ]).then(
                () => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        if (this.currentIdOperador) {
                            if (searchText !== '') {
                                this.searchText = searchText;
                                this.getCuadrillas();
                            }
                            else {
                                this.searchText = searchText;
                                this.getCuadrillas();
                            }
                        }
                    });
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Obtiene tas cuadrillas existentes
     *
     * @returns {Promise<any>}
     */
    getOperadores(): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'session/users', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onOperadoresChanged.next(response.data.users);
                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Get team information
     *
     * @returns {Promise<Team[]>}
     */
    getCuadrillas(): Promise<Team[]>
    {
        this.setShowCuadrillas();

        // Quita la pregunta seleccionada
        this.onCurrentCuadrillaChanged.next([null, null]);
        this.onCurrentCuadrillaEmpleadoChanged.next(null);

        return this.getCuadrillasByFilter(this.currentIdOperador);

    }

    /**
     * Get todos by filter
     *
     * @param idOperador
     * @returns {Promise<Team[]>}
     */
    getCuadrillasByFilter(idOperador): Promise<Team[]>
    {
        let myData = {
            data: {
                filter: {
                    users_id: idOperador,
                    date_ini: this.filter.date_ini,
                    date_end: this.filter.date_fin
                }
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'team/filter', myParam, httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            this.dataCuadrillasAll = response.data.teams.map(datTeam => {
                                // return new Team().deserialize(datTeam);
                                return new Team(datTeam);
                            });

                            this.dataCuadrillasAll = FuseUtils.filterArrayByString(this.dataCuadrillasAll, this.searchText);
                        } else {
                            this.dataCuadrillasAll = null;
                        }

                        this.onCuadrillasChanged.next(this.dataCuadrillasAll);

                        resolve(this.dataCuadrillasAll);

                    }, reject);
        });

    }

    /**
     * Actualiza el Param Id
     * @param idService
     */
    setCurrentIdOperador(idService): void {
        this.currentIdOperador = idService;
    }

    /**
     * Get imagen of team
     * @param idCuadrilla
     */
    getImage(idCuadrilla): Promise<any> {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'team/image/' + idCuadrilla, {headers: httpOptions.headers, responseType: 'blob'})
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    }, reject);
        });
    }

    setShowCuadrillas(): void {
        this.showCuadrilla = true;
        this.showEmpleados = !this.showCuadrilla;
    }
    setShowEmpleados(): void {
        this.showEmpleados = true;
        this.showCuadrilla = !this.showEmpleados;
    }

    setFilterData(idOperador: number, dateIni: string, dateFin: string): void{
        this.filter = {
            users_id: idOperador,
            date_ini: dateIni,
            date_fin: dateFin
        };
    }

    /**
     * Set current question
     *
     * @param id
     */
    setCurrentCuadrilla(id): void
    {
        this.currentCuadrilla = this.dataCuadrillasAll.find(todo => {
            return todo.id === id;
        });

        this.onCurrentCuadrillaChanged.next([this.currentCuadrilla, 'edit']);
        this.onCurrentCuadrillaEmpleadoChanged.next(null);
    }

    /**
     * Set current question
     *
     * @param id
     */
    setCurrentCuadrillaEmpleado(id): void
    {

        this.currentCuadrilla = this.dataCuadrillasAll.find(todo => {
            return todo.id === id;
        });

        this.onCurrentCuadrillaChanged.next([null, null]);
        this.onCurrentCuadrillaEmpleadoChanged.next(this.currentCuadrilla);

    }

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
     empleadosCuadrillaDelete(id, idTeam): Promise<any> {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'team/staff/' + id, httpOptions).subscribe(
                (response: any) => {
                    this.currentCuadrilla = response.data.team;
                    this.dataCuadrillasAll.find(team => {
                        return team.id === idTeam;
                    }).staffs = response.data.team.staffs;

                    this.onCurrentCuadrillaEmpleadoChanged.next(this.currentCuadrilla);

                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Save a new data
     * @param token
     * @param car
     */

    empleadosCuadrillaCreate(data): Promise<any> {
        // data.questions_groups_id = this.currentIdGroup;
        const json = JSON.stringify(data);
        const myData = {
            data: {
                staff: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'team/staff', myParam, httpOptions).subscribe(
                (response: any) => {
                    if (response.code === 200) {
                        this.currentCuadrilla = response.data.team;
                        this.dataCuadrillasAll.find(team => {
                            return team.id === data.teams_id;
                        }).staffs = response.data.team.staffs;

                        this.onCurrentCuadrillaEmpleadoChanged.next(this.currentCuadrilla);
                    }

                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Get dataAll
     *
     * @returns {Promise<any>}
     */
    empleadosGetAll(): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'staff', httpOptions)
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    }, reject);
        });
    }

    //////////// COPIAS


    /**
     * Get all filters
     *
     * @returns {Promise<any>}
     */
    /*
    getFilters(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/todo-filters')
                .subscribe((response: any) => {
                    this.filters = response;
                    this.onFiltersChanged.next(this.filters);
                    resolve(this.filters);
                }, reject);
        });
    }
    */

    /**
     * Obtiene todos los grupos de preguntas
     *
     * @returns {Promise<any>}
     */
    /*
    getGruposPreguntaByService(idService): Promise<any>
    {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'questiongroups/byservice/' + idService, httpOptions)
                .subscribe(
                    (response: any) => {

                        //Quita las preguntas
                        this.onPreguntasChanged.next(null);

                        resolve(response);
                    }, reject);
        });

    }
    */





    /**
     * Toggle selected todo by id
     *
     * @param id
     */
    /*
    toggleSelectedTodo(id): void
    {
        // First, check if we already have that todo as selected...
        if ( this.selectedTodos.length > 0 )
        {
            for ( const todo of this.selectedTodos )
            {
                // ...delete the selected todo
                if ( todo.id === id )
                {
                    const index = this.selectedTodos.indexOf(todo);

                    if ( index !== -1 )
                    {
                        this.selectedTodos.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedTodosChanged.next(this.selectedTodos);

                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedTodos.push(
            this.preguntas.find(todo => {
                return todo.id === id;
            })
        );

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }
    */

    /**
     * Toggle select all
     */
    /*
    toggleSelectAll(): void
    {
        if ( this.selectedTodos.length > 0 )
        {
            this.deselectTodos();
        }
        else
        {
            this.selectTodos();
        }

    }
    */

    /**
     * Select todos
     *
     * @param filterParameter
     * @param filterValue
     */
    /*
    selectTodos(filterParameter?, filterValue?): void
    {
        this.selectedTodos = [];

        // If there is no filter, select all todos
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedTodos = this.preguntas;
        }
        else
        {
            this.selectedTodos.push(...
                this.preguntas.filter(todo => {
                    return todo[filterParameter] === filterValue;
                })
            );
        }

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }
    */

    /**
     * Deselect todos
     */
    /*
    deselectTodos(): void
    {
        this.selectedTodos = [];

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }
    */



    /**
     * Set current question
     *
     * @param id
     */
    /*
    setNewPregunta(): void
    {
        this.currentPregunta = new Question();
        this.onCurrentCuadrillaChanged.next([this.currentPregunta, 'new']);
        // const filterGroup = this.routeParams.filterGroup;

        // TODO: Cambiar el location.go
    }
    */

    /**
     * Update the todo
     *
     * @param todo
     * @returns {Promise<any>}
     */
    /*
    updateTodo(todo): any
    {
        return new Promise((resolve, reject) => {

            this._httpClient.post('api/todo-todos/' + todo.id, {...todo})
                .subscribe(response => {

                    this.getCuadrillas().then(todos => {

                        resolve(todos);

                    }, reject);
                });
        });
    }
    */

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    /*
    activate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questions/activate/' + id + '/' + type, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }
    */

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    /*
    delete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'questions/' + id, httpOptions).subscribe(
                (response: any) => {
                    this.getCuadrillas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
    */

    /**
     * Update data
     * @param token
     * @param car
     */
    /*
    update(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                question: data
            }
        };
        const myParam = JSON.stringify(myData);

        // TODO: Falta obtener la imágen de referencia en caso de existir.

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questions/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    this.getCuadrillas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }
    */



    /**
     * Get data of item
     * @param token
     * @param car
     */
    /*
    getOne(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'questions/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
    */




    /**
     * Save a new data
     * @param token
     * @param car
     */
    /*
    grupoCreate(data): Promise<any>{
        data.service_type_id = this.currentIdOperador;
        const json = JSON.stringify(data);
        const myData = {
            data: {
                questiongroup: data
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'questiongroups', myParam, httpOptions).subscribe(
                (response: any) => {
                    this.getCuadrillas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
    */

    /**
     * Update data
     * @param token
     * @param car
     */
    /*
    grupoUpdate(data): Promise<any>{
        const json = JSON.stringify(data);
        const myData = {
            data: {
                questiongroup: data
            }
        };
        const myParam = JSON.stringify(myData);

        // TODO: Falta obtener la imágen de referencia en caso de existir.

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questiongroups/' + data.id, myParam, httpOptions).subscribe(
                (response: any) => {
                    this.getCuadrillas();
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }
    */

    /**
     * Delete
     * @param id
     * @returns {Promise<T>}
     */
    /*
    grupoDelete(id): Promise<any>{
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.urlAPI + 'questiongroups/' + id, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
    */

    /**
     * Activate
     * @param id
     * @param type
     * @returns {Promise<T>}
     */
    /*
    grupoActivate(id, type): Promise<any>{
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        let httpOptions2 = httpOptions;
        httpOptions2.headers = httpOptions2.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'questiongroups/activate/' + id + '/' + type, myParam, httpOptions2).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }
    */
}
