import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordersStatus'
})
export class OrdersStatusPipe implements PipeTransform {

    transform(value: number, type: string = 'nombre'): string {
        if (type == 'color') {
            switch (value) {
                case 10:
                    return 'lightgray';
                    break;
                case 20:
                    return 'green';
                    break;
                case 30:
                    return 'darkcyan';
                    break;
                case 40:
                    return 'blue';
                    break;
                case 50:
                    return 'gray';
                    break;
                case 90:
                    return 'red';
                    break;
                default:
                    return 'darkred';
                    break;
            }
        } else {
            switch (value) {
                case 10:
                    return 'Nueva';
                    break;
                case 20:
                    return 'En proceso';
                    break;
                case 30:
                    return 'Concluida';
                    break;
                case 40:
                    return 'Cerrada (Por admin)';
                    break;
                case 50:
                    return 'Pendiente';
                    break;
                case 90:
                    return 'Cancelada';
                    break;
                default:
                    return 'ERR';
                    break;
            }
        }
    }
}
