import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';

import { SharedModule } from './../shared/shared.module';
import { PreguntasService } from 'app/main/preguntas/preguntas.service';
import { PrincipalComponent } from 'app/main/preguntas/principal/principal.component';
import { PreguntasSidebarComponent } from 'app/main/preguntas/sidebars/preguntas-sidebar.component';
import { PreguntasListComponent } from 'app/main/preguntas/preguntas-list/preguntas-list.component';
import { PreguntasListItemComponent } from 'app/main/preguntas/preguntas-list/preguntas-list-item/preguntas-list-item.component';
import { PreguntasDetailsComponent } from 'app/main/preguntas/preguntas-details/preguntas-details.component';
import { GrupoFormDialogComponent } from 'app/main/preguntas/grupo-form/grupo-form.component';
import { QuestionsTypePipe } from '../../models/pipes/questions-type.pipe';

const routes: Routes = [
    {
        path     : 'preguntas',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    /*
    {
        path     : 'preguntas/all',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/all/:todoId',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/tag/:tagHandle',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/tag/:tagHandle/:todoId',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/filter/:filterService',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/filter/:filterService/:filterGroup',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path     : 'preguntas/filter/:filterService/:filterGroup/:todoId',
        component: PrincipalComponent,
        resolve  : {
            todo: PreguntasService
        }
    },
    {
        path      : '**',
        redirectTo: 'preguntas/all'
    }
    */
];

@NgModule({
    declarations: [
        PrincipalComponent,
        PreguntasSidebarComponent,
        PreguntasListComponent,
        PreguntasListItemComponent,
        PreguntasDetailsComponent,
        GrupoFormDialogComponent,


    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,

        NgxDnDModule,

        FuseSharedModule,
        FuseSidebarModule,

        SharedModule

    ],
    providers   : [
        PreguntasService
    ],
    entryComponents: [
        GrupoFormDialogComponent
    ]
})
export class PreguntasModule
{
}
