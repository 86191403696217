<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-100" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <a routerLink="/"><mat-icon class="secondary-text s-18">home</mat-icon></a>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <a routerLink="/checklist"><span class="secondary-text">CHECKLIST</span></a>
            </div>
            <div class="h1">{{'CHECKLIST.ADD.TITLE' | translate}}</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <!--Subtítulo y descripción de la sección -->
        <!--<div class="h1">Agregar usuario</div>-->
        <!--
        <p class="pt-16 pb-32">
            Da de alta un nuevo usuario
        </p>
        -->

        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

            <!-- REACTIVE FORM EXAMPLE -->

            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" >

                <div class="h2 mb-24">Datos del checklist</div>

                <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Tipo de servicio</mat-label>
                        <mat-select class="account-selection" placeholder="Seleccione un tipo de servicio"
                                    name="service_type_id" formControlName="service_type_id">
                            <mat-option *ngFor="let _dataServiceType of dataServiceType" [value]="_dataServiceType.id">
                                {{_dataServiceType.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error >
                            Tipo de servicio es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Versión</mat-label>
                        <input matInput name="version" formControlName="version" maxlength="20" required>
                        <!--<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>-->
                        <mat-error >
                            Versión es <strong>requerido</strong>
                        </mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field>
                        <mat-label>Fecha de inicio</mat-label>
                        <input matInput [matDatepicker]="picker" name="dt_start" formControlName="dt_start">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker startView="month" [startAt]="startDate"></mat-datepicker>
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="end end">
                    <button mat-raised-button type="button" color="warn" class="accent-50 mr-8" routerLink="/checklist">
                        Cancelar
                    </button>
                    <button mat-raised-button type="button" color="accent" [disabled]="!form.valid" (click)="saveCheck()">
                        Guardar
                    </button>
                </div>

            </form>

        </div>

    </div>
</div>