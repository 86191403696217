import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '../../main/general/http-error.interceptor';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSidebarModule } from '@fuse/components';

import { ChecklistListaComponent } from './checklist-lista/checklist-lista.component';
import { ChecklistService } from './checklist.service';
import { ChecklistAddComponent } from './checklist-add/checklist-add.component';
import { ChecklistEditComponent } from './checklist-edit/checklist-edit.component';
import { ChecklistPreguntasComponent } from './checklist-preguntas/checklist-preguntas.component';
import { DetallePreguntaDialogComponent } from './checklist-preguntas/detalle-pregunta/detalle-pregunta.component';
import { AddPreguntaDialogComponent } from './checklist-preguntas/add-preguntas/add-pregunta.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from './../shared/shared.module';

const routes: Routes = [
    {
        path        : 'checklist',
        component   : ChecklistListaComponent,
        resolve     : {
            data    : ChecklistService
        }
    },
    {
        path        : 'checklist/add',
        component   : ChecklistAddComponent
    },
    {
        path        : 'checklist/edit/:id',
        component   : ChecklistEditComponent
    },
    {
        path        : 'checklist/questions/:id',
        component   : ChecklistPreguntasComponent,
        resolve     : {
            data    : ChecklistService
        }
    }
];

@NgModule({
    declarations: [
        ChecklistListaComponent,
        ChecklistAddComponent,
        ChecklistEditComponent,
        ChecklistPreguntasComponent,
        DetallePreguntaDialogComponent,
        AddPreguntaDialogComponent

    ],
    imports     : [
        HttpClientModule,
        BrowserModule,

        RouterModule.forChild(routes),

        TranslateModule,

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatListModule,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,

        SharedModule

    ],
    /*
    providers     : [
        UsuariosListaService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ]*/
})


export class ChecklistModule { }
