import {Deserializable} from './deserializable.model';
import {User} from './users.model';
import {Point} from './point.model';
import {Checklist} from './checklist.model';

export class Orders implements Deserializable
{
    id: number;
    users_id: number;
    site_point_id: number;
    checklist_id: number;
    dt_assignment: any;
    status: number;
    data_start_date: any;
    data_start_time: any;
    data_start_geolocation: {
        type: string,
        coordinates: [0, 0]
    };
    data_end_time: any;
    data_end_comments: string;
    data_end_geolocation: {
        type: string,
        coordinates: [0, 0]
    };
    dt_start: any;
    dt_end: any;
    created_at: any;
    updated_at: any;
    user_id: number;
    user_operator: User;
    site_point: Point;
    checklist: Checklist;



    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        {
            this.id = data.id || 0;
            this.users_id = data.users_id|| 0;
            this.site_point_id = data.site_point_id|| 0;
            this.checklist_id = data.checklist_id || 0;
            this.dt_assignment = data.dt_assignment || '';
            this.status = data.status || 0;
            this.data_start_date = data.data_start_date || '';
            this.data_start_time = data.data_start_time || '';
            this.data_start_geolocation = data.data_start_geolocation || {
                    type: 'point',
                    coordinates: [0, 0]
                };
            this.data_end_comments = data.data_end_comments || '';
            this.data_end_time = data.data_end_time || '';
            this.data_end_geolocation = data.data_end_geolocation || {
                    type: 'point',
                    coordinates: [0, 0]
                };
            this.dt_start= data.dt_start || '';
            this.dt_end= data.dt_end || '';
            this.created_at= data.created_at || '';
            this.updated_at= data.updated_at || '';
            this.user_id= data.user_id || 0;
            this.user_operator= data.user_operator || null;
            this.site_point= data.site_point || null;
            this.checklist= data.checklist ||null;
        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        this.user_operator = new User().deserialize(input.user_operator);
        this.site_point = new Point().deserialize(input.site_point);
        this.checklist = new Checklist().deserialize(input.checklist);
        return this;
    }

}
