<div id="page-cuadrillas" class="page-layout carded left-sidebar inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar sidebar_cuadrillas" name="cuadrillas-main-sidebar" position="left" lockedOpen="gt-md">
        <cuadrillas-sidebar></cuadrillas-sidebar>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header" fxLayout="row" fxLayoutAlign="start center">

            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="sidebar-toggle" fxHide.gt-md
                        (click)="toggleSidebar('cuadrillas-main-sidebar')">
                    <mat-icon>menu</mat-icon>
                </button>

                <div class="search" fxLayout="row" fxLayoutAlign="start center" *ngIf="!currentTeamEmpleados">
                    <mat-icon class="secondary-text">search</mat-icon>
                    <input [formControl]="searchInput" placeholder="Busca una cuadrilla" fxFlex>
                </div>

            </div>

        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->


        <div class="content-card" >

            <!--
            <app-cuadrillas-lista *ngIf="!currentTeamEmpleados"></app-cuadrillas-lista>
            -->

            <!--
            <app-empleados-lista *ngIf="currentTeamEmpleados"></app-empleados-lista>
            -->


            <!-- CONTENT -->


            <div class="content" >
                <app-cuadrillas-lista *ngIf="!currentTeamEmpleados"></app-cuadrillas-lista>
                <app-empleados-lista *ngIf="currentTeamEmpleados"></app-empleados-lista>
                <!--
                <preguntas-list fusePerfectScrollbar fxFlex></preguntas-list>
                <preguntas-details fusePerfectScrollbar fxFlex></preguntas-details>
                -->

            </div>


            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
