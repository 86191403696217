import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Team } from 'app/models/team.model';
import { CuadrillasService } from 'app/main/cuadrillas/cuadrillas.service';

@Component({
    selector     : 'cuadrillas-detlle-dialog',
    templateUrl  : './cuadrillas-detalle.component.html',
    styleUrls    : ['./cuadrillas-detalle.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CuadrillasDetalleDialogComponent
{
    serviceId: number;
    action: string;
    dataCuadrilla: Team;
    dataCuadrillaForm: FormGroup;
    dialogTitle: string;
    imageToShow: any;
    isImageLoading: boolean;
    lat: number;
    lng: number;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CuadrillasDetalleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _cuadrillasService: CuadrillasService
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.serviceId = _data.gruaId;;
            this.dialogTitle = 'Modificar cuadrilla';
            this.dataCuadrilla = _data.dataCheck;
        }
        else if ( this.action === 'show' )
        {
            this.serviceId = _data.gruaId;
            this.dialogTitle = 'Información de cuadrilla';
            this.dataCuadrilla = _data.dataCuadrilla;
            this.lat = this.dataCuadrilla.geolocation.coordinates[0];
            this.lng = this.dataCuadrilla.geolocation.coordinates[1];
            this.getImageFromService(this.dataCuadrilla.id);
        }
        else
        {
            this.dialogTitle = 'Nueva cudrilla';
            this.dataCuadrilla = new Team();
        }

        this.dataCuadrillaForm = this.createGroupForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createGroupForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.dataCuadrilla.id],
            user: [{
                value: this.dataCuadrilla.user,
                disabled: true
            }],
            user_name: [{
                value: this.dataCuadrilla.user.name,
                disabled: true
            }],
            data_truck_img: [this.dataCuadrilla.data_truck_img],
            geolocation: [this.dataCuadrilla.geolocation],
            geolocation_lat: [{
                value: this.dataCuadrilla.geolocation.coordinates[0],
                disabled: true
            }],
            geolocation_long: [{
                value: this.dataCuadrilla.geolocation.coordinates[0],
                disabled: true
            }],
        });
    }

    getImageFromService(idCuadrilla): void {
        this._cuadrillasService.getImage(idCuadrilla)
            .then((data) => {
                this.createImageFromBlob(data);
                this.isImageLoading = false;
            })
            .catch((error: any) => {
                this.isImageLoading = true;
            });

    }

    createImageFromBlob(image: Blob): void {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }


}
