import {Deserializable} from './deserializable.model';
export class Point{

    id: number;
    customers_id: number;
    data_code: string;
    data_type: string;
    data_ip: string;
    data_sector: string;
    data_c2: string;
    data_direction: string;
    geolocation: {
        type: string,
        coordinates: [0, 0]
    };
    active: number;
    createdAt: any;
    updatedAt: any;
    orders_count: number

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.customers_id = data.customers_id || 1;
        this.data_code = data.data_code || '';
        this.data_type = data.data_type || '';
        this.data_ip = data.data_ip || '';
        this.data_sector = data.data_sector || '';
        this.data_c2 = data.data_c2 || '';
        this.data_direction = data.data_direction || '';
        this.geolocation =
            data.geolocation
            ||
            {
                type: 'point',
                coordinates: [0, 0]
            };
        this.active = data.active || 0;
        this.createdAt = data.createdAt || null;
        this.updatedAt = data.updatedAt || null;
        this.orders_count = data.orders_count || 0;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}
