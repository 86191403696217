import {Deserializable} from './deserializable.model';
import { Checklist } from './checklist.model';
import { Question } from './question.model';

export class ChecklistQuestions implements Deserializable{

    id: number;
    checklist_id: number;
    checklist: Checklist;
    questions_id: number;
    question: Question;
    created_at: any;
    updated_at: any;


    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        this.id = data.id || 0;
        this.checklist_id = data.checklist_id || 0;
        this.checklist = data.checklist || null;
        this.questions_id = data.questions_id || 0;
        this.question = data.question || null;
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;

    }

    deserialize(input: any): this {
        Object.assign(this, input);

        this.checklist = new Checklist().deserialize(input.checklist);
        this.question = new Question().deserialize(input.question);

        return this;
    }

}