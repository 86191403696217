<div *ngIf="preguntas && preguntas.length === 0" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <span class="no-preguntas-text hint-text">No hay preguntas!</span>
</div>
<div class="preguntas-list" ngxDroppable [model]="preguntas" (out)="onDrop($event)" [@animateStagger]="{value:'50'}">
    <preguntas-list-item class="preguntas-list-item has-handle"
                    *ngFor="let pregunta of preguntas" [pregunta]="pregunta"
                    ngxDraggable
                    [model]="pregunta"
                    (click)="readPregunta(pregunta.id)"
                    [ngClass]="{'current-todo':pregunta?.id == currentPregunta?.id}"
                    [@animate]="{value:'*',params:{y:'100%'}}">
    </preguntas-list-item>
</div>
