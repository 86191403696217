import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { User } from 'app/models/users.model';

@Component({
    selector: 'app-usuarios-pwd',
    templateUrl: './usuarios-pwd.component.html',
    styleUrls: ['./usuarios-pwd.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsuariosPwdComponent implements OnInit {

    action: string;
    user: User;
    userForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<UsuariosPwdComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UsuariosPwdComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Cambio de password';
            this.user = _data.user;
        }
        else
        {
            this.dialogTitle = 'ERR';
            this.user = new User({});
        }

        this.userForm = this.createContactForm();
    }

    /**
     * On init
     */
    ngOnInit(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup
    {
        return this._formBuilder.group({
            id          : [this.user.id],
            name        : [this.user.name],
            password    : [this.user.password, [Validators.required, Validators.maxLength(20)]]
        });
    }
}
