import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule} from '@angular/material/table';
import { MatListModule } from '@angular/material/list';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';

import { AgmCoreModule } from '@agm/core';

import { TranslateModule} from '@ngx-translate/core';

import { OrdenesService } from 'app/main/ordenes/ordenes.service';
import { PrincipalComponent } from 'app/main/ordenes/principal/principal.component';
import { OrdenesListadoComponent } from './ordenes-listado/ordenes-listado.component';
import { OrdenesSidebarsComponent } from './ordenes-sidebars/ordenes-sidebars.component';
import { OrdersStatusPipe } from '../../models/pipes/orders-status.pipe';
import { OrdersChecklistStatusPipe } from '../../models/pipes/orders-checklist-status.pipe';
import { OrdenesChecklistListadoComponent } from './ordenes-checklist-listado/ordenes-checklist-listado.component';
import { OrdenesCrearDialogComponent } from './ordenes-crear-dialog/ordenes-crear-dialog.component';
import { PreguntaImagenDialogComponent } from './ordenes-checklist-listado/pregunta-imagen-dialog/pregunta-imagen-dialog.component';

const routes: Routes = [
    {
        path     : 'ordenes',
        component: PrincipalComponent,
        resolve  : {
            todo: OrdenesService
        }
    },
];

@NgModule({
    declarations: [
        PrincipalComponent,
        OrdenesListadoComponent,
        OrdenesSidebarsComponent,
        OrdenesChecklistListadoComponent,

        OrdersStatusPipe,
        OrdersChecklistStatusPipe,
        OrdenesCrearDialogComponent,
        PreguntaImagenDialogComponent

    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatRadioModule,
        MatChipsModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatListModule,

        NgxDnDModule,

        FuseSharedModule,
        FuseSidebarModule,

        TranslateModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

    ],
    providers   : [
        OrdenesService
    ],
})
export class OrdenesModule { }
