import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordersChecklistStatus'
})
export class OrdersChecklistStatusPipe implements PipeTransform {

    transform(value: number, type: string = 'nombre'): string {
        if (type == 'color') {
            switch (value) {
                case 10:
                    return 'gightgray';
                    break;
                case 11:
                    return 'gray';
                    break;
                case 20:
                    return 'green';
                    break;
                case 30:
                    return 'red';
                    break;
                default:
                    return 'darkred';
                    break;
            }
        } else {
            switch (value) {
                case 10:
                    return 'Nueva';
                    break;
                case 11:
                    return 'Modificada';
                    break;
                case 20:
                    return 'Aceptada';
                    break;
                case 30:
                    return 'Solicita cambio';
                    break;
                default:
                    return 'ERR';
                    break;
            }
        }
    }
}
