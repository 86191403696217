export const locale = {
    lang: 'es',
    data: {
        CHECKLIST: {
            LIST: {
                TITLE: 'CHECKLIST'
            }
        }
    }
};
