import {Deserializable} from './deserializable.model';

export class Question
{
    id: number;
    questions_groups_id: number;
    description: string;
    type: number;
    required: number;
    comments_visible: number;
    comments_required: number;
    comments_label: string;
    comments_type: number;
    reference: string;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?)
    {
        data = data || {};
        {
            this.id = data.id || 0;
            this.questions_groups_id = data.questions_groups_id || 0;
            this.description = data.description || '';
            this.type = data.type || 1;
            this.required = data.required || 1;
            this.comments_visible = data.comments_visible || 0;
            this.comments_required = data.comments_required || 0;
            this.comments_label = data.comments_label || '';
            this.comments_type = data.comments_type || 1;
            this.reference= data.reference || '';

        }
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

}
