<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="dataGrupoForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nombre</mat-label>
                    <input name="name" formControlName="name" matInput maxlength="200" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Descripción</mat-label>
                    <textarea name="description" formControlName="description" matInput type="text" max-rows="4"></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Prioridad</mat-label>
                    <input matInput type="number" name="priority" formControlName="priority" maxlength="5" required>
                </mat-form-field>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button *ngIf="action !== 'edit'"
                mat-raised-button
                class="mat-accent"
                (click)="matDialogRef.close(dataGrupoForm)"
                [disabled]="dataGrupoForm.invalid"
                aria-label="Guardar">
            Guardar
        </button>

        <button *ngIf="action === 'edit'"
                mat-raised-button
                class="mat-accent"
                (click)="matDialogRef.close(['save',dataGrupoForm])"
                [disabled]="dataGrupoForm.invalid"
                aria-label="Guardar">
            Guardar
        </button>

    </div>
</div>
