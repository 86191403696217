import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Question } from 'app/models/question.model';
import { OrdenesService } from '../ordenes.service';
import { User } from '../../../models/users.model';
import { Point } from '../../../models/point.model';
import {ServiceType} from '../../../models/service-type.model';


@Component({
    selector: 'app-ordenes-crear-dialog',
    templateUrl: './ordenes-crear-dialog.component.html',
    styleUrls: ['./ordenes-crear-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrdenesCrearDialogComponent implements OnInit {

    groupId: number;
    checklistId: number;
    action: string;
    dialogTitle: string;

    pointsList: Point[];

    startDate: any;
    dateIni: FormControl;
    public form: FormGroup;

    // Para filtro de operadores
    selectedOperador: FormControl;
    dataOperadores: User[];

    // Para filtro de tipo de servicio
    selectedType: FormControl;
    dataTiposServicio: ServiceType[];

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddPreguntaDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<OrdenesCrearDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _ordenesService: OrdenesService,
        private _matSnackBar: MatSnackBar,
    )
    {
        // Set the defaults
        this._unsubscribeAll = new Subject();
        this.action = _data.action;
        this.groupId = _data.groupId;
        this.checklistId = _data.checklistId;
        this.startDate = new Date();
        this.dateIni = new FormControl(new Date(), [Validators.required]);
        this.selectedOperador = new FormControl(null, [Validators.required]);
        this.form = this.createDataForm();


        this.dialogTitle = 'Ordenes';

        this.getInventario(this.startDate);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Carga información de los operadores
        this._ordenesService.onOperadoresChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.dataOperadores = data.filter(dataOper => ( dataOper.role & 2 ) === 2);
                // this.selectedOperador = new User();
            });

        // Carga información de los tipos de servicios
        this._ordenesService.onTiposServicioChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                if (data == null) {
                    this.dataTiposServicio = null;
                } else {
                    this.dataTiposServicio = data.filter(dataType => dataType.active === 1);
                }
            });

        this._ordenesService.getOperadores();
        this._ordenesService.getTiposServicios();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create product form
     *
     * @returns {FormGroup}
     */
    createDataForm(): FormGroup {
        return this._formBuilder.group({
            dateIni: [new Date(), [Validators.required]],
            selectedOperador: [null, [Validators.required]],
            selectedTipoServicio: [null, [Validators.required]]
        });

    }

    /**
     * Get Site Points disponible
     */
    public getInventario(dateSelected: Date): void{
        this._ordenesService.getInventarioDisponible(dateSelected)
            .then((response) => {
                this.pointsList = response.filter(dataPoint => dataPoint.orders_count <= 0);
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en obtención de información', 'Error', {
                    verticalPosition: 'top',
                    duration        : 3000
                });
            });
    }

    /**
     * Change date of supervision
     * @param event
     */
    addEventDatePicker(event: MatDatepickerInputEvent<Date>): void {
        const dateSelected: Date = new Date(event.value);

        this.getInventario(dateSelected);
    }

    /**
     * Save data
     * @param data
     */
    public guardarDatos(data: any): void {

        let sitesIds: number[] = [];
        if (data.length > 0) {
            data.forEach(function (dataValue) {
                sitesIds.push(dataValue.value.id);
            });

            this._ordenesService.ordersCreate(this.form.controls['selectedTipoServicio'].value.id, this.form.controls['selectedOperador'].value.id, this.form.controls['dateIni'].value, sitesIds)
                .then(() => {
                    this._matSnackBar.open('Ordenes asignadas', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.matDialogRef.close('success');

                })
                .catch((error: any) => {
                    this._matSnackBar.open('Error en creación de ordenes', 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });
                    this.matDialogRef.close(null);
                });
        }

    }

}
