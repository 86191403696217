export const locale = {
    lang: 'es',
    data: {
        CHECKLIST: {
            QUESTIONS: {
                TITLE1: 'PREGUNTAS',
                TITLE2: 'CHECKLIST',
            }
        }
    }
};
