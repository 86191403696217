<!-- SIDEBAR HEADER -->
<div fxLayout="column" fxLayoutAlign="space-between start"
     class="header accent p-24 pb-4">
    <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">supervisor_account</mat-icon>
        <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">CUADRILLAS  </span>
    </div>

    <div class="cssTipoService" fxLayout="column" [@animate]="{value:'*',params:{delay:'300ms'}}">
        <div class="title">Operadores </div>
        <mat-form-field floatLabel="never">
            <mat-select class="account-selection" placeholder="Seleccione un operador"
                        [(ngModel)]="selectedOperador" (selectionChange)="selectTipo()" >
                <mat-option [value]="sinOperador">Todos</mat-option>
                <mat-option *ngFor="let _dataOperador of dataOperadores" [value]="_dataOperador">
                    {{_dataOperador.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<!-- / SIDEBAR HEADER -->

<!-- SIDEBAR CONTENT -->
<div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{y:'50px'}}">

    <div class="nav material2 px-24 py-24">

        <div class="mat-subheading-2" >Filtro por fechas</div>

        <mat-form-field>
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="dateIni">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="month" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Fecha de fin</mat-label>
            <input matInput [matDatepicker]="picker2" [formControl]="dateFin">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 startView="month" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>

    </div>

    <div class="p-24">
        <button mat-raised-button
                class="mat-accent add-todo-button w-100-p"
                *ngIf="selectedOperador.id != 0"
                (click)="buscarCuadrillas()"
                aria-label="Buscar">
            Buscar
        </button>
    </div>

</div>
<!-- / SIDEBAR CONTENT -->
