export const locale = {
    lang: 'es',
    data: {
        TOOLBAR: {
            PERFIL: {
                MYPERFIL: 'Mi perfil',
                LOGOUT: 'Cerrar sesión'
            }
        }
    }
};
