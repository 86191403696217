import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '../../main/general/http-error.interceptor';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSidebarModule } from '@fuse/components';

import { GruasListaComponent } from './gruas-lista/gruas-lista.component';
import { GruasService } from './gruas.service';
import { GruasAddComponent } from './gruas-add/gruas-add.component';
import { GruasEditComponent } from './gruas-edit/gruas-edit.component';
import { GruasMapaDialogComponent } from './gruas-mapa/gruas-mapa.component';
import { GruasHistorialComponent } from './gruas-historial/gruas-historial.component';

import { MatToolbarModule } from '@angular/material/toolbar';


const routes: Routes = [
    {
        path        : 'gruas',
        component   : GruasListaComponent,
        resolve     : {
            data    : GruasService
        }
    },
    {
        path        : 'gruas/add',
        component   : GruasAddComponent
    },
    {
        path        : 'gruas/edit/:id',
        component   : GruasEditComponent
    },
    {
        path        : 'gruas/ubicaciones/:id',
        component   : GruasHistorialComponent,
        resolve     : {
            data    : GruasService
        }
    }
];

@NgModule({
    declarations: [
        GruasListaComponent,
        GruasAddComponent,
        GruasEditComponent,
        GruasMapaDialogComponent,
        GruasHistorialComponent

    ],
    imports     : [
        HttpClientModule,
        BrowserModule,

        RouterModule.forChild(routes),

        TranslateModule,

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatDatepickerModule,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        FuseConfirmDialogModule,
        FuseSidebarModule

    ],
    /*
    providers     : [
        UsuariosListaService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ]*/
})


export class GruasModule { }
