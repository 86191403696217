import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Question } from 'app/models/question.model';
import { ChecklistService } from '../../checklist.service';

@Component({
    selector     : 'add-pregunta-dialog',
    templateUrl  : './add-pregunta.component.html',
    styleUrls    : ['./add-pregunta.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AddPreguntaDialogComponent implements OnInit
{
    groupId: number;
    checklistId: number;
    action: string;
    dialogTitle: string;
    typesOfShoes: string[];
    questionsList: Question[];

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddPreguntaDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<AddPreguntaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _checklistService: ChecklistService,
        private _matSnackBar: MatSnackBar,
    )
    {
        // Set the defaults
        this.action = _data.action;
        this.groupId = _data.groupId;
        this.checklistId = _data.checklistId;

        this.dialogTitle = 'Pregunta';
        this.typesOfShoes = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._checklistService.getPreguntasDisponiblesByGroup(this.checklistId, this.groupId)
            .then((response) => {
                this.questionsList = response;
            })
            .catch((error: any) => {
                this._matSnackBar.open('Error en obtención de información', 'Error', {
                    verticalPosition: 'top',
                    duration        : 3000
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    public guardarDatos(data: any): void {

        let idQuestions: number[] = [];
        if (data.length > 0) {
            data.forEach(function (dataValue) {
                idQuestions.push(dataValue.value.id);
            });

            this._checklistService.checklistQuestionCreate(this.checklistId, idQuestions)
                .then(() => {
                    this._matSnackBar.open('Preguntas actualizadas', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.matDialogRef.close('success');

                })
                .catch((error: any) => {
                    this._matSnackBar.open('Error en actualización', 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });
                    this.matDialogRef.close(null);
                });
        }

        this.matDialogRef.close(null);

    }
}
