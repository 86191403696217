import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estatusActivo'
})
export class EstatusActivoPipe implements PipeTransform {

  transform(value: number): unknown {
      switch (value) {
          case 1:
              return 'Activo';
              break;
          case 2:
              return 'Inactivo';
              break;
          default:
              return 'ERR';
              break;
      }

  }

}
