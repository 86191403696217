import { Injectable } from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { GLOBAL } from '../../global/global';
import { LoginService } from 'app/main/login/login.service';
import { User } from '../../models/users.model';
import { Point } from '../../models/point.model';
import { Checklist } from '../../models/checklist.model';
import { Orders } from '../../models/orders.model';
import { OrdersChecklist } from '../../models/orders-checklist.model';

const httpOptions = {
    headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ',
        }
    )};

@Injectable({
  providedIn: 'root'
})
export class OrdenesService {

    // Behaviors for orders list
    onOperadoresChanged: BehaviorSubject<any>;
    onSitiosChanged: BehaviorSubject<any>;
    onChecklistsChanged: BehaviorSubject<any>;
    onOrdenesChanged: BehaviorSubject<any>;
    onSeccionChanged: BehaviorSubject<any>;
    onOrdenSelected: BehaviorSubject<Orders>;
    onSitePointsChanged: BehaviorSubject<any>;
    onTiposServicioChanged: BehaviorSubject<any>;
    dataOrdenesAll: any[];

    // Behaviors for orders-checklist list
    onOrdenesChecklistChanged: BehaviorSubject<any>;
    dataOrdenesChecklistAll: any[];
    dataSitePointsListAll: any[];

    currentSeccion: string;
    currentOrdenSelected: Orders;

    // For current Filters
    filterSelOperador: User;
    filterSelSitio: Point;
    filterSelChecklist: Checklist;
    filterDateIni: Date;
    filterDateFin: Date;
    filterSelStatus: Number;


    routeParams: any;
    filter: {
        users_id: number,
        date_ini: string,
        date_fin: string
    };

    private urlAPI: string;
    private moduleAPI: string;

    constructor(private _httpClient: HttpClient,
                private _location: Location,
                private _loginService: LoginService
    ) {
        this.onOperadoresChanged = new BehaviorSubject('');
        this.onTiposServicioChanged = new BehaviorSubject(null);
        this.onSitiosChanged = new BehaviorSubject('');
        this.onChecklistsChanged = new BehaviorSubject('');
        this.onOrdenesChanged = new BehaviorSubject(null);
        this.onSeccionChanged = new BehaviorSubject('');
        this.onOrdenesChecklistChanged = new BehaviorSubject(null);
        this.onOrdenSelected = new BehaviorSubject(null);
        this.onSitePointsChanged = new BehaviorSubject(null);
        this.currentOrdenSelected = null;
        this.currentSeccion = '';

        this.urlAPI = GLOBAL.url;
        this.moduleAPI = 'questions';

        // OBtiene tipos de servicio.
        // this.getOperadores();
        this.filter = {
            users_id: 0,
            date_ini: '',
            date_fin: ''
        };
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Devuelve información
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getOperadores(),
                this.getSitios(),
                this.getChecklists(),
            ]).then(
                () => {
                    /*
                     this.onSearchTextChanged.subscribe(searchText => {
                     if (this.currentIdOperador) {
                     if (searchText !== '') {
                     this.searchText = searchText;
                     this.getCuadrillas();
                     }
                     else {
                     this.searchText = searchText;
                     this.getCuadrillas();
                     }
                     }
                     });
                     */
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Obtiene los operadores existentes
     *
     * @returns {Promise<any>}
     */
    getOperadores(): Promise<any> {

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'session/users', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onOperadoresChanged.next(response.data.users);
                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Obtiene los Sitios de inventario
     *
     * @returns {Promise<any>}
     */
    getSitios(): Promise<any> {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'points', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onSitiosChanged.next(response.data.points);
                        resolve(response);
                    }, reject);
        });
    }

    /**
     * Obtiene los operadores existentes
     *
     * @returns {Promise<any>}
     */
    getTiposServicios(): Promise<any> {

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'servicetypes', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onTiposServicioChanged.next(response.data.tiposservicio);
                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Obtiene los checklists existentes
     *
     * @returns {Promise<any>}
     */
    getChecklists(): Promise<any> {

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'checklist', httpOptions)
                .subscribe(
                    (response: any) => {
                        this.onChecklistsChanged.next(response.data.checklist);
                        resolve(response);
                    }, reject);
        });

    }

    /**
     * Set filters and get information
     *
     * @param idOperador
     * @returns {Promise<Team[]>}
     */
    getOrdenesByFilter(selOperador: User, selSitio: Point, selChecklist: Checklist, dateIni: Date, dateFin: Date, selStatus: Number): Promise<Orders[]> {
        this.filterSelOperador = selOperador;
        this.filterDateIni = dateIni;
        this.filterDateFin = dateFin;
        this.filterSelSitio = selSitio;
        this.filterSelChecklist = selChecklist;
        this.filterSelStatus = selStatus;

        return this.getOrdenes();
    }

    /**
     * Get ordenes with filters pre-selected
     * @returns {Promise<T>}
     */
    getOrdenes(): Promise<Orders[]> {
        let myData = {
            data: {
                filters: {
                    user_id: this.filterSelOperador.id,
                    date_ini: new DatePipe('en').transform(this.filterDateIni, 'yyyy-MM-dd'),
                    date_end: new DatePipe('en').transform(this.filterDateFin, 'yyyy-MM-dd'),
                    site_points_id: this.filterSelSitio.id,
                    checklist_id: this.filterSelChecklist.id,
                    status: this.filterSelStatus
                }
            }
        };

        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'orders/filter', myParam, httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            // this.dataOrdenesAll = response.data.orders;
                            this.dataOrdenesAll = response.data.orders.map(data => {
                                return new Orders(data);
                            });

                        } else {
                            this.dataOrdenesAll = null;
                        }

                        this.onOrdenesChanged.next(this.dataOrdenesAll);

                        resolve(this.dataOrdenesAll);

                    }, reject);
        });

    }

    /**
     * Set seccion of module
     * @param openSeccion
     */
    setSeccion(openSeccion: string): void {
        this.currentSeccion = openSeccion;
        this.onSeccionChanged.next(this.currentSeccion);
    }

    /**
     * Get orders-checklist by Order
     *
     * @param idOperador
     * @returns {Promise<Team[]>}
     */
    getOrdersChecklistByOrder(selOrder: Orders): Promise<OrdersChecklist[]> {
        this.setOrderSelected(selOrder);
        let myData = {
            data: {
                filters: {
                    order_id: selOrder.id
                }
            }
        };

        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'orders-checklist/byorder', myParam, httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            // this.dataOrdenesAll = response.data.orders;
                            this.dataOrdenesChecklistAll = response.data.order_checklist.map(data => {
                                return new OrdersChecklist(data);
                            });

                        } else {
                            this.dataOrdenesChecklistAll = null;
                        }

                        this.onOrdenesChecklistChanged.next(this.dataOrdenesChecklistAll);
                        resolve(this.dataOrdenesChecklistAll);

                    }, reject);
        });

    }

    /**
     * Set order selected
     * @param selOrder
     */
    setOrderSelected(selOrder: Orders): void {
        this.currentOrdenSelected = selOrder;
        this.onOrdenSelected.next(this.currentOrdenSelected);
    }

    /**
     * Invoke getOrdersCheckListByOrder with current order
     */
    getOrdersChecklistCurrentOrder(): void{
        if (this.currentOrdenSelected) {
            this.getOrdersChecklistByOrder(this.currentOrdenSelected);
        }
    }

    /**
     * Get site points for an order
     * @param dateOrder
     * @returns {Promise<T>}
     */
    getInventarioDisponible(dateOrder: Date): Promise<Point[]> {
        let myData = {
            data: {
                filter: {
                    date_order: new DatePipe('en').transform(dateOrder, 'yyyy-MM-dd'),
                    fields: [
                        'id', 'customer_id', 'data_code', 'data_type', 'data_ip', 'data_sector', 'data_c2', 'active', 'geolocation', 'orders_count'
                    ]
                }
            }
        };

        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'points/byOrderAssignment', myParam, httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            // this.dataOrdenesAll = response.data.orders;
                            this.dataSitePointsListAll = response.data.points.map(data => {
                                return new Point(data);
                            });

                        } else {
                            this.dataSitePointsListAll = null;
                        }

                        this.onSitePointsChanged.next(this.dataSitePointsListAll);
                        resolve(this.dataSitePointsListAll);

                    }, reject);
        });

    }

    /**
     * Save a new data
     * @param token
     * @param car
     */
    ordersCreate(serviceTypeId: number, usersId: number, dtAssignment: Date, ordersId: number[]): Promise<any> {
        const myData = {
            data: {
                orders: {
                    service_type_id: serviceTypeId,
                    users_id: usersId,
                    dt_assignment: new DatePipe('en').transform(dtAssignment, 'yyyy-MM-dd'),
                    site_points: ordersId
                }
            }
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlAPI + 'orders', myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });

    }

    /**
     * Change status for order
     * @param serviceTypeId
     * @param usersId
     * @param dtAssignment
     * @param ordersId
     * @returns {Promise<T>}
     */
    orderChangeStatus(orderId: number, statusActual: number, statusNuevo: number): Promise<any> {
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'orders/change-status/' + orderId + '/' + statusActual + '/' + statusNuevo, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Get imagen of team
     * @param idCuadrilla
     */
    getImageOrderCheckList(idOrderChecklist): Promise<any> {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'orders-checklist/image/' + idOrderChecklist, {headers: httpOptions.headers, responseType: 'blob'})
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    }, reject);
        });
    }


    /**
     * Change status for order-checklist
     * @param serviceTypeId
     * @param usersId
     * @param dtAssignment
     * @param ordersId
     * @returns {Promise<T>}
     */
    orderChecklistChangeStatus(orderChecklistId: number, statusActual: number, statusNuevo: number): Promise<any> {
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'orders-checklist/change-status/' + orderChecklistId + '/' + statusActual + '/' + statusNuevo, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Change status for order-checklist
     * @param serviceTypeId
     * @param usersId
     * @param dtAssignment
     * @param ordersId
     * @returns {Promise<T>}
     */
    orderChecklistChangeStatusAllQuestions(orderChecklistId: number, statusNuevo: number): Promise<any> {
        const myData = {
        };
        const myParam = JSON.stringify(myData);

        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlAPI + 'orders-checklist/change-status-all/' + orderChecklistId + '/' + statusNuevo, myParam, httpOptions).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Get imagen of team
     * @param idCuadrilla
     */
    getProtocol(idOrder): Promise<any> {
        httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this._loginService.getToken());

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlAPI + 'orders/' + idOrder + '/protocol', {headers: httpOptions.headers, responseType: 'blob'})
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    }, reject);
        });
    }

}
