<div id="page-checklist" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">list_alt</mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'CHECKLIST.LIST.TITLE' | translate}}</span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <!--
            <div class="search-wrapper  ml-sm-32">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="end center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Buscar una grua">
                </div>
            </div>
            -->
            <!-- / SEARCH -->

            <!-- ACTIONS -->
            <div class="toolbar-action-button-row" fxLayout="row" fxLayoutAlign="end center" >
                <button [routerLink]="'/checklist/add'" mat-raised-button color="primary" aria-label="Nuevo Checklist" title="Nuevo Checklist">Nuevo Checklist</button>
            </div>
            <!-- / ACTIONS -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="checklist-table"
                       #table [dataSource]="dataSource"
                       matSort
                       [@animateStagger]="{value:'50'}"
                       fusePerfectScrollbar
                       multiTemplateDataRows >

                <ng-container matColumnDef="id" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="service_type.name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de servicio</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.service_type.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="version" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Versión</mat-header-cell>
                    <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{data.version}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_record">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh. Registro</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.dt_record | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dt_start">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Fh. Inicio</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.dt_start | date: 'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="questions_count">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Preguntas</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                        <p class="text-truncate">{{data.questions_count }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let data" (click)="$event.stopPropagation()">
                        <div class="toolbar-action-button-row">
                            <button mat-mini-fab color="accent" title="Editar" aria-label="Editar" [routerLink]="'/checklist/edit/'+data.id" >
                                <mat-icon class="active-icon " >edit</mat-icon>
                            </button>
                            <button mat-mini-fab color="accent" title="Ver preguntas" aria-label="Ver preguntas" (click)="verPreguntas(data)" >
                                <mat-icon class="active-icon " >question_answer</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let data; columns: displayedColumns;"
                         class="datarow"
                         matRipple
                        >
                </mat-row>

            </mat-table>

            <mat-paginator #paginator
                           [length]="dataSource.filteredData.length"
                           [pageIndex]="0"
                           [pageSize]="10"
                           [pageSizeOptions]="[10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
